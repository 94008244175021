import React from 'react'

import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

export default function LinearWithValueLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        {props.value ?
          <LinearProgress variant="determinate" color={props.color} value={props.value} />
          :
          <LinearProgress color={props.color} />
        }
      </Box>
      {props.value && <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>}
    </Box>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})
