import axios from 'axios'

const doFeasibilityRequest = async ({ siteid, latitude, longitude, height, apikey }) => {

    if (!apikey)
        return {
            siteid,
            feasible: 'Error',
            type: 'error',
            data: 'Invalid or No API Key',
        }

    const params = {
        siteid,
        latitude,
        longitude,
        key: apikey,
        channel: '28east-internal-bulk',
        include_attributes: true,
        parse_response: true,
        product_level: 'row',
        show_errors: true
    }

    if (height) params.height = height

    try {
        let response = await axios.get(process.env.REACT_APP_FEASIBILITY_URL, { params })
        // let data = response?.data || response
        // data.siteid = siteid
        let results = handleSuccessResponse(response, siteid)

        return results
    } catch (error) {
        return [{
            siteid,
            feasible: 'Error',
            type: 'error',
            data: error?.message || error || 'An error occurred',
        }]
    }


}

// const handleSuccessResponse = ({ services, success, code, message }) => {
const handleSuccessResponse = (response, siteid) => {
    if(!response) return;

    let { services, success, code, message } = response?.data

    if (!success) return [{
        siteid,
        feasible: 'Error',
        type: 'error',
        data: message || code || 'An error occurred',
    }]

    if (Array.isArray(services) && services.length === 0) return [{
        siteid,
        feasible: 'Not Feasibile',
    }]

    return services
}

export {
    doFeasibilityRequest
}