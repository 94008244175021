import React from 'react';
import './App.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import Admin from './components/Layouts/Admin'
import Dashboard from './components/Layouts/Dashboard'
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { setApps, setClients, setProviders, setServices, setStatuses } from './cache/actions'
import ClientsController from "./Controllers/ClientsController";
import DataController from "./Controllers/DataController";
import Login from './views/Login';
import { connect } from "react-redux";
import { getAuth } from "./cache/selectors";
import Clients from './views/Clients';
// import firebaseConfig from './assets/firebaseConfig.json';
// // var firebase = require("firebase/app"); 
// import firebase from 'firebase';
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"

const hist = createBrowserHistory();

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: purple,
    },
    status: {
        danger: 'orange',
    },
});

const authentication = {
    isAuthenticated: false
}

const mapStateToProps = state => {
    const auth = getAuth(state);
    authentication.isAuthenticated = auth.isSignedIn;
    return { auth };
};

const App = props => (
    <MuiThemeProvider theme={theme}>
        <div className="App">
            <Router history={hist}>
                <Switch>
                    <PrivateRoute auth={props.auth} path='/admin' component={Admin} key="admin" />
                    <PrivateRoute auth={props.auth} path='/dashboard' component={Dashboard} key="dashboard" /> 
                    <Route path='/login' component={Login} key="login" />
                    <Redirect from="/" exact to="/admin/dashboard" key="dashboard" />
                </Switch>
            </Router>
        </div>
    </MuiThemeProvider>
);

// export default App;
const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                authentication.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
} 

class AppContainer extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: !!props.auth
        }

        // firebase.initializeApp(firebaseConfig);
        this.getCache();
        // this.googleAuth();
    }

    getCache = () => {
        Promise.all([
            DataController.GetServices(),
            DataController.GetProviders(),
            DataController.GetStatuses(),
            ClientsController.GetClients(),
            DataController.GetApps(),
        ])
            .then(results => {
                if (!results[0].success && !results[0].code)
                    this.props.setServices(results[0]);
                if (!results[1].success && !results[1].code)
                    this.props.setProviders(results[1]);
                if (!results[2].success && !results[2].code)
                    this.props.setStatuses(results[2]);
                if (!results[3].success && !results[3].code)
                    this.props.setClients(results[3]);
                if (!results[4].success && !results[4].code)
                    this.props.setApps(results[4]);
            });
    };

    componentDidMount = () => {
        // firebase.auth().onAuthStateChanged(user => {
        //     this.setState({ isSignedIn: !!user })
        // })
    }

    render() {
        return (
            <App auth={this.props.auth} />
        );
    }
}

export default connect(
    mapStateToProps,
    { setApps, setClients, setProviders, setServices, setStatuses }
)(AppContainer);


