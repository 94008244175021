let ClientsController = {};

ClientsController.GetApps = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "apps/")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetClients = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.AddClient = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.UpdateClient = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.DeleteClient = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetSubscriptions = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/subscriptions")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetClientSubscriptions = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/" + id + "/subscriptions")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.AddSubscription = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/subscriptions", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.UpdateSubscription = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/subscriptions/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.DeleteSubscription = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/subscriptions/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetClientAPIKeys = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/" + id + "/keys")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetAPIKeys = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/keys")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetAPIKeysPagination = (items, page) => {
    return new Promise((resolve, reject) => {
        if( !items || !page || items === 0 || page === 0) return;

        fetch(process.env.REACT_APP_API_BASE_URL + "clients/keys/"+items+"/"+page)
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.AddAPIKey = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/keys", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.UpdateAPIKey = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/keys/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.DeleteAPIKey = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/keys/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetClientsDomains = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/domains")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetClientDomains = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/" + id + "/domains")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.AddClientDomain = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/domains", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.UpdateClientDomain = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        console.log('newData', newData)
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/domains/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.DeleteClientDomain = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/domains/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

ClientsController.GetClientsUsage = (interval, token) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "clients/usage?interval="+interval, {
            method: 'get',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};


export default ClientsController;
