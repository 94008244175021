import React, {Component} from "react";
import PropTypes from "prop-types";
import ViewTable from "../components/Tables/ViewTable";
import Utils from "../Utils/Utils";
import GMPUsageBillingReportsController from "../Controllers/GMPUsageBillingReportsController";
import Flatten from "../Utils/Flatten";
import {makeStyles, useTheme} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
}));

class GMPUsageBillingReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'GMP Usage Billing Reports',
            columns: [],
            data: [],
            accounts:[]
        };
        this.initialiseData();
    }

    handleRowAdd = (newData) => {
        const data = [...this.state.data];
        data.push(newData);
        this.setState({data: data});
    };
    handleRowUpdate = (newData, oldData) => {
        const data = [...this.state.data];
        data[data.indexOf(oldData)] = newData;
        this.setState({data: data});
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        data.splice(data.indexOf(oldData), 1);
        this.setState({data: data});
    };
    handleError = (error) => {
        alert(error)
    };
    handleRowClick = (client) => {
        console.log(client)
    };

    initialiseData = () => {
        console.log(process.env.PUBLIC_URL);
        let date = Utils.getCurrentYear() + Utils.getCurrentMonth();
        // GMPUsageBillingReportsController.GetBillingAllAccountDetails().then(row => {
        //     console.log(row)
        // });
        GMPUsageBillingReportsController.GetUsageDataByDate(date,(row) => {
            this.parseRow(row);
        });
    };

    parseRow = (row) => {
        let flattened = Flatten(row, {safe: true, arraytostring: true});
        if(!this.state.columns || this.state.columns.length === 0) this.addColumns(Object.keys(flattened));
        this.handleRowAdd(flattened);
    };

    parseData = (data) => {
        let keys = [];
        this.state.data.forEach(x => {
            let flattened = Flatten(x, {safe: true, arraytostring: true});
            data.push(flattened);
            if (!keys || keys.length === 0) keys = Object.keys(flattened);
        });
        let columns = this.state.columns;
        if(!columns || columns.length === 0) this.addColumns(keys);
    };

    addColumns = (keys) => {
        let columns = this.state.columns;
        keys.forEach(k => {
            columns.push({title: k, field: k, readonly: true, editable: 'never'});
        });
        this.setState({columns: columns});
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <ViewTable
                    title={this.state.title}
                    data={this.state.data}
                    columns={this.state.columns}
                />
            </div>
        );
    }
}

// GMPUsageBillingReports.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(GMPUsageBillingReports);
export default GMPUsageBillingReports;
