import React, { Component } from 'react';
import PerfectScrollbar from 'perfect-scrollbar'
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Navbar from '../Navbars/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../../routes/routes";
import image from "../../assets/img/logo.png";
import logo from "../../assets/img/logo.png";
import PropTypes from 'prop-types'
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from 'clsx';
import firebase from 'firebase';
import { connect } from "react-redux";
import { getAuth } from "../../cache/selectors";
import { setAuth } from '../../cache/actions'

const drawerWidth = 240;
const appStyle = theme => ({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh"
    },
    mainPanel: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100%)`
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    content: {
        marginTop: "70px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 123px)"
    },
    map: {
        marginTop: "70px"
    },
    drawerOpen: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.spacing(9) + 1}px)`//,
        },
    },
});

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            return (
                <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        })}
    </Switch>
);

const mapStateToProps = state => {
    const auth = getAuth(state);
    return { auth };
};

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: image,
            color: "active",
            hasImage: true,
            fixedClasses: "dropdown show",
            mobileOpen: true,
            currentHeading: "28East Admin Console",
        };
    }
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    handleCurrentHeading = heading => {
        // this.setState({ currentHeading: heading });
    };
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    };
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            const ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        window.addEventListener("resize", this.resizeFunction);
    };
    handleDrawer() {
        if (this.state.mobileOpen) this.setState({ mobileOpen: false });
        else this.setState({ mobileOpen: true });
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    };

    handleLogin = () => {

    }

    handleLogout = () => { 
        this.props.setAuth({ isSignedIn: false });
        firebase.auth().signOut(); 
    }

    render() {
        const { classes, ...rest } = this.props;
        return (
            <div className={classes.wrapper}>
                {/* <Navbar
                    open={this.state.mobileOpen}
                    headingText={this.state.currentHeading}
                    handleDrawerToggle={this.handleDrawerToggle}
                    onClick={() => this.handleDrawer()}
                    handleLogout={this.handleLogout}
                    handleLogin={this.handleLogin}
                /> */}
                <Sidebar
                    routes={routes}
                    logoText={"dashboard"}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    handleCurrentHeading={this.handleCurrentHeading}
                    {...rest}
                />
                <div
                    className={clsx(classes.mainPanel, {
                        [classes.drawerOpen]: this.state.mobileOpen,
                        [classes.drawerClose]: !this.state.mobileOpen,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: this.state.mobileOpen,
                            [classes.drawerClose]: !this.state.mobileOpen,
                        }),
                    }}
                    ref="mainPanel">
                    <div className={classes.content}>
                        <div className={classes.container}
                        >{switchRoutes}</div>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(connect(mapStateToProps, { setAuth })(Dashboard));
