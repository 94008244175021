let Utils = {};

Utils.sortAlphabetically = (arr) => {
    if (!arr) return arr
    if (typeof arr === 'object' && !Array.isArray(arr)) return arr
    else if (!Array.isArray(arr)) return arr
    return arr.sort((a, b) => {
        // if(!a.full_name || !b.full_name) return 0
        if (a.full_name > b.full_name) {
            return -1;
        }
        if (b.full_name > a.full_name) {
            return 1;
        }
        return 0;
    })
}
Utils.sortObjectAlphabetically = (obj) => {
    if (!obj) return obj
    if (typeof obj !== 'object') return obj
    let toSort = []
    for (let i in obj) {
        toSort.push([i, obj[i]])
    }
    toSort.sort((a, b) => {
        // if(!a.full_name || !b.full_name) return 0
        if (a[0] < b[0]) {
            return -1;
        }
        if (b[0] < a[0]) {
            return 1;
        }
        return 0;
    })
    let newObj = {}
    toSort.forEach(s => {
        newObj[s[0]]= s[1]
    })
    return newObj
}

Utils.getCurrentDate = (seperator = '-') => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return (dd + seperator + mm + seperator + yyyy);
};

Utils.getCurrentMonth = () => {
    let today = new Date();
    let mm = today.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;
    return mm;
};

Utils.getCurrentYear = () => {
    return new Date().getFullYear();
};

Utils.getCurrentDay = () => {
    let today = new Date();
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    return dd;
};

Utils.getTodayTimestamp = (seperator) => {
    if (!seperator) seperator = '-'
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return (yyyy + seperator + mm + seperator + dd + 'T00:00:00.000Z');
}

Utils.getUUID = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
};

Utils.isEmpty = (obj) => {
    // Speed up calls to hasOwnProperty
    let hasOwnProperty = Object.prototype.hasOwnProperty;

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
};
function pad2(n) { return n < 10 ? '0' + n : n }

export default Utils;
