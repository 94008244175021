import Utils from "../Utils/Utils";

let DataController = {};

DataController.GetApps = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "apps/")
            .then(res => res.json())
            .then(data => resolve(Utils.sortAlphabetically(data)))
            .catch(reject)
    })
};

DataController.AddApp = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "apps", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.UpdateApp = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "apps/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.DeleteApp = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "apps/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};


DataController.GetServices = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/services")
            .then(res => res.json())
            .then(data => resolve(Utils.sortAlphabetically(data)))
            .catch(reject)
    })
};

DataController.AddService = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/services", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.UpdateService = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/services/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.DeleteService = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/services/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.GetStatuses = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/statuses")
            .then(res => res.json())
            .then(data => resolve(Utils.sortAlphabetically(data)))
            .catch(reject)
    })
};

DataController.AddStatus = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/statuses", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.UpdateStatus = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/statuses/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.DeleteStatus = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/statuses/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.GetProviders = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/providers")
            .then(res => res.json())
            .then(data => resolve(Utils.sortAlphabetically(data)))
            .catch(reject)
    })
};

DataController.AddProvider = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/providers", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.UpdateProvider = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/providers/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.DeleteProvider = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/providers/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.GetGeometryTypes = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/geometry/types")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.GetServiceCoverage = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/servicecoverage")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.AddServiceCoverage = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/servicecoverage", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.UpdateServiceCoverage = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/servicecoverage/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.DeleteServiceCoverage = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/servicecoverage/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.GetDataSources = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/sources")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.AddDataSource = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/sources", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.UpdateDataSource = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/sources/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.DeleteDataSource = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/sources/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.GetTodayRequestEstimates = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/reports/today/estimate")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.GetHostRequestByApps = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/reports/requests")
            .then(res => res.json())
            .then(data =>resolve(data))
            .catch(reject)
    })
};

DataController.GetProviderLastUpdatedDates = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/providers/lastupdate")
            .then(res => res.json())
            .then(data =>resolve(data))
            .catch(reject)
    })
};


DataController.GetExternalAPIs = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/externalapis")
            .then(res => res.json())
            .then(data => resolve(Utils.sortAlphabetically(data)))
            .catch(reject)
    })
};

DataController.AddExternalAPIs = (data) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/externalapis", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.UpdateExternalAPIs = (oldData, newData) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/externalapis/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

DataController.DeleteExternalAPIs = (id) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "data/externalapis/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

export default DataController;
