import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import DataController from "../Controllers/DataController";
import Utils from "../Utils/Utils";
import { makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getProviders, getServices, getAuth } from "../cache/selectors";
import ControlledOpenSelect from "../components/DropdownSelect/ControlledOpenSelect";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
}));

const mapStateToProps = state => {
    const providers = getProviders(state);
    const services = getServices(state);
    const auth = getAuth(state);
    return { providers, services, auth };
};

class ServiceCoverage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Service Coverages',
            providers: props.providers,
            services: props.services,
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },

                {
                    title: 'Service',
                    field: 'service',
                    required: true,
                    lookup: {},
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={Utils.sortAlphabetically(this.props.services)}
                        />
                    ),
                },
                {
                    title: 'Provider',
                    field: 'provider',
                    required: true,
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={Utils.sortAlphabetically(this.props.providers)}
                        />
                    ),
                    lookup: {},
                },
                { title: 'Data Available', field: 'data_available', type: 'boolean' },
                { title: 'Has External API', field: 'external_request', type: 'boolean' },
            ],
            data: [],
        };
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    componentDidMount() {
        this.initialiseColumnData();
        this.initialiseData();
    }

    onRefresh = () => {
        this.initialiseColumnData();
        this.initialiseData();
    }

    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            }
            console.log("newData")
            console.log(newData)
            delete data.service_id;
            if (data.service) {
                data.service_id = +data.service
            }
            delete data.provider_id;
            if (data.provider && this.state.providers) {
                data.provider_id = +data.provider;
            }
            if (!data.service_id) {
                return reject('Invalid property: Service');
            }
            if (!data.provider_id) {
                return reject('Invalid property: Provider');
            }

            return resolve(data);
        });
    }

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate();

            DataController.AddServiceCoverage(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            DataController.UpdateServiceCoverage(oldData, newData)
                .then(response => {
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            DataController.DeleteServiceCoverage(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleError = (error) => {
        this.loading(false)
        alert(error)
    };
    handleRowClick = (client) => {
        console.log(client)
    };

    initialiseColumnData = () => {
        let columns = this.state.columns;
        columns[1].lookup = this.state.services.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        columns[2].lookup = this.state.providers.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        this.setState({ columns: columns });
    };

    initialiseData = () => {
        this.loading(true)
        Promise.all([
            DataController.GetServiceCoverage()
        ]).then(results => {
            this.handleResults(results)
        });

    };

    handleResults = results => {
        if (!results[0].success && !results[0].code) {
            let parents = {};
            results[0].forEach(row => {
                if (this.state.providers && this.state.providers.length > 0) {
                    let x = this.state.providers.filter(e => { return e.name === row.provider })
                    if (x && x.length > 0) row.provider = x[0].id
                }
                if (this.state.services && this.state.services.length > 0) {
                    let x = this.state.services.filter(e => { return e.name === row.service })
                    if (x && x.length > 0) row.service = x[0].id
                }
                // if (!parents[row.service]) parents[row.service] = row.id;
                // else row.parentId = parents[row.service]
            });

            this.setState({ data: results[0] })
            this.loading(false);
            console.log(this.state)
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    validation={this.validation}
                    // handleRowClick={this.handleClientClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    onRefresh={this.onRefresh}
                />
            </div>
        );
    }
}

// ServiceCoverage.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps)(ServiceCoverage));
export default connect(mapStateToProps)(ServiceCoverage);
