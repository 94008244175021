import { doFeasibilityRequest } from "./FeasibilityAPI";


const handleFeasibilityRequest = async (request, apikey, retry = 0, onError) => {
  if(!request) return

  try {
    request.apikey = apikey
    return await doFeasibilityRequest(request)
  } catch (error) {
    if (retry < 5) { // limiting to 5 retries
      const delay = Math.pow(2, retry) * 100; // Exponential backoff
      setTimeout(() => handleFeasibilityRequest(request, retry + 1), delay);
    } else {
      if (onError) onError(error)
    }
  }
}

const doBulkFeasibilityApiRequests = async (locations, apikey, onProgress, onError) => {
  if (locations.length === 0) return

  let concurrentLimit = 3
  let totalLocations = locations.length
  let completedLocations = 0
  let progress = 0
  let results = []

  const processRequests = async () => {

    if (locations.length === 0) return;

    const currentChunk = locations.splice(0, concurrentLimit);
    let chunkResults = await Promise.allSettled(currentChunk.map(location => handleFeasibilityRequest(location, apikey)));

    completedLocations += chunkResults.length
    progress = completedLocations / totalLocations * 100
    if (onProgress) onProgress(progress)

    chunkResults.forEach(chunk => {
      results.push(...chunk.value)
    })

    return processRequests();
  };

  // Start processing requests
  await processRequests();

  return results
}

export {
  doBulkFeasibilityApiRequests
}

