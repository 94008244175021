import React, { Component, useState } from "react";
import PropTypes, { object } from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import ClientsController from "../Controllers/ClientsController";
import Flatten from "../Utils/Flatten";
import Utils from "../Utils/Utils";
import { Box, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Switch, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getProviders, getServices, getAuth } from "../cache/selectors";
import DataController from "../Controllers/DataController";
import ReactJson from "react-json-view"
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import { InfoOutlined, QuestionAnswerOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    textArea: {
        minWidth: '500px'
    },
    switch: {
        "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: "pink"
        }
    }
}));

const mapStateToProps = state => {
    const providers = getProviders(state);
    const services = getServices(state);
    const auth = getAuth(state);
    return { providers, services, auth, };
};

class DataSources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Data Sources',
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                { title: 'Is usable', field: 'is_usable', type: 'boolean' },
                { title: 'Coverage id', field: 'coverage_id', required: true, lookup: {}, editable: 'never' },
                { title: 'Service', field: 'service', required: true, lookup: {} },
                { title: 'Provider', field: 'provider', required: true, lookup: {} },
                { title: 'Data URL', field: 'data_url' },
                { title: 'Data method', field: 'data_method', lookup: {} },
                { title: 'Data type', field: 'data_type', lookup: {} },
                { title: 'Data request', field: 'data_request', initialEditValue: '{}' },
                { title: 'Data response', field: 'data_response', lookup: {} },
                { title: 'Data HTTPS', field: 'data_https', type: 'boolean', initialEditValue: false },
                { title: 'Data gzip', field: 'data_gzip', type: 'boolean', initialEditValue: false },
                { title: 'Data headers', field: 'data_headers', initialEditValue: '{}' },
                { title: 'Data update frequency', field: 'data_update_frequency', initialEditValue: 1 },
                { title: 'Data include exclude property name', field: 'data_include_exclude_property_name', initialEditValue: 'false' },
                { title: 'Data include value', field: 'data_include_value', initialEditValue: 'false' },
                { title: 'Data exclude value', field: 'data_exclude_value', initialEditValue: 'false' },
                { title: 'Data status property name', field: 'data_status_property_name', initialEditValue: 'false' },
                { title: 'Data last update id', field: 'data_last_update_id', type: 'numeric', initialEditValue: 0 },
                { title: 'Data current published update id', field: 'data_current_published_update_id', required: true, type: 'numeric', initialEditValue: 0 },
                { title: 'Data status property 1', field: 'data_status_property_1', initialEditValue: 'false' },
                { title: 'Data status property 2', field: 'data_status_property_2', initialEditValue: 'false' },
                { title: 'Data status property 3', field: 'data_status_property_3', initialEditValue: 'false' },
                { title: 'Data status property 4', field: 'data_status_property_4', initialEditValue: 'false' },
                { title: 'Data status property 5', field: 'data_status_property_5', initialEditValue: 'false' },
                { title: 'Data status property 6', field: 'data_status_property_6', initialEditValue: 'false' },
                { title: 'Data status property 7', field: 'data_status_property_7', initialEditValue: 'false' },
                { title: 'Data status property 8', field: 'data_status_property_8', initialEditValue: 'false' },
                { title: 'Geometry id', field: 'geometry_id', lookup: {}, editable: 'never' },
                { title: 'Geometry Name', field: 'geometry_name', lookup: {} },
                {
                    title: 'Attribution',
                    field: 'attribution',
                    cellStyle: {
                        minWidth: '600px'
                    },
                    initialEditValue: '[]',
                    editComponent: props => {
                        return (
                            <TextField
                                id="attribution"
                                fullWidth
                                multiline
                                rows={10}
                                variant="outlined"
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                            />
                        )
                    }
                },
                { title: 'Schedule Update', field: 'schedule_update', type: 'boolean', initialEditValue: false, emptyValue: false},
                {
                    title: 'Sector Details',
                    field: 'sector_details',
                    cellStyle: {
                        minWidth: '600px'
                    },
                    // initialEditValue: '{"sheet_id":"","sheet_key":"","sheet_name":""}',
                    editComponent: props => {
                        return (
                            <TextField
                                id="sector_details"
                                placeholder='{"sheet_id":"","sheet_key":"","sheet_name":""}'
                                fullWidth
                                multiline
                                rows={10}
                                variant="outlined"
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                            />
                        )
                    }
                },
            ],
            data: [],
            coverageData: [],
            geometryTypes: [],
            days: {}
        };
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    componentDidMount() {
        this.getData();
    }

    onRefresh = () => {
        this.getData();
    }

    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            }
            delete data.coverage_id;
            if ((data.service && data.provider) && this.state.coverageData) {
                this.state.coverageData.forEach(row => {
                    if (row.service === data.service && row.provider === data.provider)
                        data.coverage_id = row.id;
                })
            }
            delete data.geometry_id
            if (data.geometry_name && this.state.geometryTypes) {
                this.state.geometryTypes.forEach(row => {
                    if (row.name === data.geometry_name)
                        data.geometry_id = row.id;
                })
            }
            if (!data.coverage_id) {
                return reject('Invalid Coverage (Service and Provider) configuration');
            }
            if (!data.data_url) {
                return reject('Invalid property: Data URL');
            }
            if (!data.data_method) {
                return reject('Invalid property: Data Method');
            }
            if (!data.data_type) {
                return reject('Invalid property: Data Type');
            }
            if (!data.data_response) {
                return reject('Invalid property: Data Response');
            }
            if (!data.geometry_id) {
                return reject('Invalid property: Geometry Name');
            }
            if (data.attributes) {
                let valid = this.validateAttribution(data.attributes)
                if (valid.error) return reject(valid.reason)
                data.attributes = JSON.stringify(data.attributes)
            }
            if (data.sector_details) {
                let valid = this.validateSectorDetails(data.sector_details)
                if (valid.error) return reject(valid.reason)
                // data.sector_details = JSON.stringify(data.sector_details)
            } else data.sector_details = null

            if (data.schedule_update !== true && data.schedule_update !== false) {
                data.schedule_update = false
            }

            return resolve(data);
        });
    }

    validateAttribution = (attributes) => {
        let result = {
            error: false,
            reason: ''
        }

        for (let attr of attributes) {

            if (attr.search_required === undefined || typeof attr.search_required !== 'boolean')
                return result = { error: true, reason: 'expected "search_required" attribute' }

            if (attr.result_required === undefined || typeof attr.result_required !== 'boolean')
                return result = { error: true, reason: 'expected boolean "result_required" attribute' }

            if (attr.search_required === false && attr.result_required === false)
                continue;

            if (attr.search_required === true && (attr.search_key === undefined || typeof attr.search_key !== 'string'))
                return result = { error: true, reason: 'expected string "search_key" attribute' }

            if (attr.result_required === true && (attr.result_key === undefined || typeof attr.result_key !== 'string'))
                return result = { error: true, reason: 'expected string "result_key" attribute' }

            if (attr.result_required === true && attr.result_type === undefined || typeof attr.result_type !== 'string' || !['string', 'number', 'boolean'].includes(attr.result_type))
                return result = { error: true, reason: 'expected string "result_type" attribute in [string, number, boolean] range' }

            if (attr.result_type === 'number') {
                if (attr.result_range === undefined) {
                    if (attr.result_default === undefined)
                        return result = { error: true, reason: 'expected array "result_range" attribute when there is no "result_default" attribute' }
                }
                else {
                    if (Array.isArray(attr.result_range) || typeof attr.result_range !== 'object')
                        return result = { error: true, reason: 'expected array "result_range" attribute' }

                    if (attr.result_range.min === undefined || typeof attr.result_range.min !== 'number' || attr.result_range.min < 0)
                        return result = { error: true, reason: 'expected number < 0 "result_range.min" attribute' }

                    if (attr.result_range.max !== undefined) {
                        if (typeof attr.result_range.max !== 'number')
                            return result = { error: true, reason: 'expected number "result_range.max" attribute' }
                    }

                }
            }
        }

        return result
    }


    validateSectorDetails = (sectorDetails) => {
        let result = {
            error: false,
            reason: ''
        }
        if(!sectorDetails) return result

        let obj
        try {
            if(typeof sectorDetails === 'string') obj = JSON.parse(sectorDetails);
            else obj = sectorDetails
        } catch(e){
            return { error: true, reason: 'invalid sector details' }
        }

        if(!obj) return { error: true, reason: 'invalid sector details' }

        if(!obj.sheet_id || obj.sheet_id.replaceAll(' ','').length === 0)
            return { error: true, reason: 'expected string "sheet_id" sector details' }
        else if(!obj.sheet_key || obj.sheet_key.replaceAll(' ','').length === 0)
            return { error: true, reason: 'expected string "sheet_key" sector details' }
        else if(!obj.sheet_name || obj.sheet_name.replaceAll(' ','').length === 0)
            return { error: true, reason: 'expected string "sheet_name" sector details' }
        return result
    }

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate();

            // if(newData.attribution) newData.attribution = JSON.parse(newData.attribution)
            DataController.AddDataSource(newData)
                .then(response => {
                    // console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };


    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (newData.attribution) newData.attribution = newData.attribution

            DataController.UpdateDataSource(oldData, newData)
                .then(response => {
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            DataController.DeleteDataSource(oldData.id)
                .then(response => {
                    // console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleError = (error) => {
        this.loading(false)
        alert(error)
    };
    handleRowClick = (client) => {
        // console.log(client)
    };

    initialiseColumnData = () => {
        let columns = this.state.columns;
        if (this.state.coverageData) {
            columns[2].lookup = this.state.coverageData.reduce((acc, cur, i) => {
                acc[cur.id] = cur.id;//`Id: ${cur.id}, Service: ${cur.service}, Provider: ${cur.provider}`;
                return acc;
            }, {});
        }
        columns[3].lookup = this.props.services.reduce((acc, cur, i) => { acc[cur.name] = cur.full_name; return acc; }, {});
        columns[4].lookup = this.props.providers.reduce((acc, cur, i) => { acc[cur.name] = cur.full_name; return acc; }, {});
        columns[6].lookup = this.state.data.reduce((acc, cur, i) => { acc[cur.data_method] = cur.data_method; return acc; }, {});
        columns[7].lookup = this.state.data.reduce((acc, cur, i) => { acc[cur.data_type] = cur.data_type; return acc; }, {});
        columns[9].lookup = this.state.data.reduce((acc, cur, i) => { acc[cur.data_response] = cur.data_response; return acc; }, {});
        columns[28].lookup = this.state.geometryTypes.reduce((acc, cur, i) => { acc[cur.id] = cur.id; return acc; }, {});
        columns[29].lookup = this.state.geometryTypes.reduce((acc, cur, i) => { acc[cur.name] = cur.name; return acc; }, {});

        this.setState({ columns: columns });
        // this.getData();
    };

    getData = () => {
        this.loading(true)
        Promise.all([
            DataController.GetServiceCoverage(),
            DataController.GetDataSources(),
            DataController.GetGeometryTypes()
        ]).then(results => {
            this.handleResults(results);
            this.initialiseColumnData();
        });
    };

    handleResults = results => {

        if (!results[0].success && !results[0].code) {
            // console.log("GetServiceCoverage", results[0])
            this.setState({ coverageData: results[0] })
        }
        if (!results[1].success && !results[1].code) {
            // console.log("GetDataSources", results[1])
            let sources = []
            for (let i in results[1]) {
                let source = results[1][i]
                if (source.attribution)
                    source.attribution = JSON.stringify(source.attribution)
                if (source.sector_details)
                    source.sector_details = JSON.stringify(source.sector_details)
                sources.push(source)
            }
            this.setState({ data: results[1] });
        }
        if (!results[2].success && !results[2].code) {
            // console.log("GetGeometryTypes", results[2])
            this.setState({ geometryTypes: results[2] });
        }
        this.loading(false);
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    validation={this.validation}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    // handleRowClick={this.handleClientClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    onRefresh={this.onRefresh}
                />
            </div>
        );
    }
}

// Subscriptions.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps)(Subscriptions));
export default connect(mapStateToProps)(DataSources);
