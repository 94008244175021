import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import UsersController from "../Controllers/UsersController";
import Flatten from "../Utils/Flatten";
import image from "../assets/img/logo.png";
import { makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux"; 
import { getAuth } from "../cache/selectors";
import Utils from "../Utils/Utils";


const mapStateToProps = state => { 
    const auth = getAuth(state);
    return { auth };
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
      },
}));
  
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Users',
            isLoading: false,
            columns: [
                { title: 'User', field: 'user', isRequired: true },
                { title: 'Password', field: 'password', type: 'string',
                render: rowData => <p>***************</p>
                }, 
                { title: 'View Authorized', field: 'view_authorized', type: 'boolean' },
                { title: 'Update Authorized', field: 'update_authorized', type: 'boolean' },
                { title: 'Edit authorized', field: 'edit_authorized', type: 'boolean' },
                { title: 'Migrate authorized', field: 'migration_authorized', type: 'boolean' },
            ],
            data: [],
        }; 
    }
    
    componentDidMount() { 
        this.initialiseData();
    }

    onRefresh = () => { 
        this.initialiseData();
    }
    
    loading = loading => {
        this.setState({isLoading: loading})
    }

    initialiseData = () => {
        this.loading(true)
        UsersController.GetUsers()
            .then(results => {   
                this.setState({data: results})
                this.loading(false);
            })
            .catch(err => console.error(err));
    };
  
    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            } 
            if (!data.user) {
                return reject('Invalid user');
            }
            if (!data.password) {
                return reject('Invalid password');
            } 
            return resolve(data);
        });
    }

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate(); 
            UsersController.AddUser(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if(newData.attribution) newData.attribution = newData.attribution
            UsersController.UpdateUser(oldData, newData)
                .then(response => {
                    if(!response.success || !response.code == 200) {
                        throw(response) 
                    }
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            UsersController.DeleteUser(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleRowClick = (row) => {
        console.log(row)
        console.log(this.props) 
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    handleRowClick={this.handleRowClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    validation={this.validation}
                    onRefresh={this.onRefresh} 
                />
            </div>
        );
    }
}

// Users.propTypes = {
//     classes: PropTypes.object.isRequired
// };

export default connect(mapStateToProps)(Users);
