import Utils from '../Utils/Utils'
import ndjsonStream from 'can-ndjson-stream';

let GMPUsageBillingReportsController = {};

GMPUsageBillingReportsController.GetUsageDataByDate = (date, callback) => {
    return new Promise((resolve, reject) => {
        if (!date) date = Utils.getCurrentYear() + Utils.getCurrentMonth();
        let url = process.env.REACT_APP_API_BASE_URL + 'reports/cloud/billing/accounts';
        fetch(url).then((response) => {
            return ndjsonStream(response.body);
        }).then(todosStream => {
            let read;
            let reader = todosStream.getReader();
            reader.read().then(read = (result) => {
                if (result.done) {
                    resolve();
                    return;
                }
                console.log(result.value);
                callback(result.value);
                reader.read().then(read).catch(reject); //recurse through the stream
            }).catch(reject);
        });
    })
};

GMPUsageBillingReportsController.GetTransactionalDataByDate = (date, billingAccountId, callback) => {
    return new Promise((resolve, reject) => {
        if (!date) date = Utils.getCurrentYear() + Utils.getCurrentMonth();

    })
};

GMPUsageBillingReportsController.GetBillingAllAccountDetails = () => {
    return new Promise((resolve, reject) => {
        fetch("http://localhost:9000/testAPI")
            .then(res => resolve(res))
            .catch(reject)


    })
};

GMPUsageBillingReportsController.GetBillingAccountDetails = (billingAccountId) => {
    return new Promise((resolve, reject) => {

    })
};


export default GMPUsageBillingReportsController;
