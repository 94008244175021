import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import UsersController from "../Controllers/UsersController";
import Flatten from "../Utils/Flatten";
import image from "../assets/img/logo.png";
import { Box, Container, Paper, Typography, makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getAuth } from "../cache/selectors";
import Utils from "../Utils/Utils";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import JsonDisplay from "../components/JsonDisplay/JsonDisplay";


const mapStateToProps = state => {
    const auth = getAuth(state);
    return { auth };
};

const appStyle = theme => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    frame: {
        padding: '20pt',
        width: '100%'
    },
    srcInput: {
        width: '40%',
        minWidth: '300px'
    },
    srcButton: {
        margin: theme.spacing(1),
    },
    inputContainer: {
        marginBottom: '15pt'
    },
    jsonBlockContainer: {
        textAlign: 'left',
        height: '600px',
        overflow: 'auto'
    },
});

class PostMessageTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            srcURLInput: 'https://demo.28east.co.za',
            srcURL: 'https://demo.28east.co.za',
            isLoading: false,
            error: false,
            expanded: false,
            response: {},
        };
    }

    componentDidMount() {
        this.initialiseData();
        window.addEventListener("message", this.receiveMessage, false);
    }

    onRefresh = () => {
        this.initialiseData();
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    initialiseData = () => {
        this.loading(true)
    };

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleChange = (event) => {
        this.setState({ srcURLInput: event.target.value })
    };

    loadMap = (event) => {
        this.setState({ srcURL: this.state.srcURLInput, response: {} })
    };

    handleToggleExpand = (event) => {
        this.setState({ expanded: !this.state.expanded })
    };

    receiveMessage = (event) => {
        console.log("!!!!!!!!!!!!!!!!!!!! receiveMessage !!!!!!!!!!!!!!!!!!!!");
        console.log("Orgin: ", event.origin);
        console.log("data: ", event.data);
        let data = event.data

        if (typeof data === "string") {
            try {
                data = JSON.parse(data)
            } catch (e) {
                this.setState({
                    response: {
                        error: 'Invalid data being parsed'
                    }
                })
                return
            }
        }

        if(data.services)
            this.setState({ response: data })
    }
    keyPress = (e) => {
        if(e.keyCode == 13){
            this.loadMap(e)
        }
     }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root}>
                <Box className={classes.frame}>

                    <Container className={classes.inputContainer}>
                        <TextField
                            className={classes.srcInput}
                            id="srcURLInput"
                            label="Website URL"
                            variant="outlined"
                            value={this.state.srcURLInput}
                            onChange={this.handleChange}
                            onKeyDown={this.keyPress}
                            required />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.srcButton}
                            onClick={this.loadMap}
                        >
                            Load URL
                        </Button>
                    </Container>
                    <Typography variant="caption">Please enter a valid URL to load via the Iframe. The format should be <b>https://domain.com</b></Typography>
                    <iframe
                        id="iframe"
                        src={this.state.srcURL}
                        allow="geolocation"
                        width="100%"
                        height="600px"
                    >
                    </iframe>

                    <Box className={classes.jsonBlockContainer} >
                        <Typography variant="h6">Post Message Response</Typography>
                        <JsonDisplay jsonData={this.state.response} />
                    </Box>
                </Box>
            </Paper>
        );
    }
}

PostMessageTest.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(connect(mapStateToProps)(PostMessageTest));
// export default withStyles(useStyles)(connect(mapStateToProps, { setAuth })(Admin));

