import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import UsersController from "../Controllers/UsersController";
import Flatten from "../Utils/Flatten";
import image from "../assets/img/logo.png";
import { Box, Container, Grid, Paper, Typography, makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getAuth } from "../cache/selectors";
import Utils from "../Utils/Utils";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import JsonDisplay from "../components/JsonDisplay/JsonDisplay";

const mapStateToProps = state => {
    const auth = getAuth(state);
    return { auth };
};

const appStyle = theme => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    frame: {
        padding: '20pt',
        width: '100%'
    },
    srcInput: {
        width: '40%',
        minWidth: '300px'
    },
    srcButton: {
        margin: theme.spacing(1),
    },
    inputContainer: {
        marginBottom: '15pt'
    },
    jsonBlockContainer: {
        textAlign: 'left'
    },
    table: {
        minWidth: 650,
      },
    gridContainer: {
        margin: '8px'
    },
    gridItem: {
        fontSize: 'larger',
        color: 'primary'
    },
    gridRow: {
        // margin: '10pt',
        borderTop: '1px rgba(0,0,0,0.2) solid',
        padding: '10pt'
    }
});

class URLParser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            srcURLInput: 'https://demo.28east.co.za',
            srcURL: 'https://demo.28east.co.za',
            isLoading: false,
            error: false,
            expanded: false,
            response: {},
            params: []
        };
    }

    componentDidMount() {
        this.initialiseData();
    }

    onRefresh = () => {
        this.initialiseData();
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    initialiseData = () => {
        this.loading(true)
    };

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleChange = (event) => {
        this.setState({ srcURLInput: event.target.value })
    };

    handleToggleExpand = (event) => {
        this.setState({ expanded: !this.state.expanded })
    };

    parseURL = () => {
        let result = {};
        let domain = this.state.srcURLInput?.split('?')

        result.domain = domain[0]
        if (domain.length <= 1) {
            this.setState({ response: result })
            return result
        }

        let params = domain[1]?.split("&") || ['']

        params.forEach(function (part) {
            if (!part) return;
            part = part.split("+").join(" "); // + to space, regexp-free version
            let eq = part.indexOf("=");
            let key = eq > -1 ? part.substr(0, eq) : part;
            let val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
            let from = key.indexOf("[");
            if (from == -1) result[decodeURIComponent(key)] = val;
            else {
                let to = key.indexOf("]", from);
                let index = decodeURIComponent(key.substring(from + 1, to));
                key = decodeURIComponent(key.substring(0, from));
                if (!result[key]) result[key] = [];
                if (!index) result[key].push(val);
                else result[key][index] = val;
            }
        });

        this.setState({ response: result })
        return result;
    }


    keyPress = (e) => {
        if (e.keyCode == 13) {
            this.parseURL()
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root}>
                <Box className={classes.frame}>

                    <Container className={classes.inputContainer}>
                        <TextField
                            className={classes.srcInput}
                            id="srcURLInput"
                            label="Website URL"
                            variant="outlined"
                            value={this.state.srcURLInput}
                            onChange={this.handleChange}
                            onKeyDown={this.keyPress}
                            required />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.srcButton}
                            onClick={this.parseURL}
                        >
                            Load URL
                        </Button>
                    </Container>
                    <Typography variant="caption">Please enter a valid URL to load all the parameters. The format should be <b>https://domain.co.za?param=value</b></Typography>

                    <Box className={classes.jsonBlockContainer}>
                        <Typography variant="h6">Parameters</Typography>
                        <Grid container className={classes.gridContainer}>
                            {this.state.response && Object.keys(this.state.response).map(key => {
                                return <Grid container key={key} xs={12} className={classes.gridRow}>
                                    <Grid item key={key+'_item_key'} className={classes.gridItem} xs={3}>
                                        {key}: {' '}
                                    </Grid>
                                    <Grid item key={key+'_item_value'} className={classes.gridItem} xs={9}>
                                        {' '} <b>{this.state.response[key]}</b>
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                        {/* <JsonDisplay jsonData={this.state.response} /> */}
                    </Box>
                </Box>
            </Paper>
        );
    }
}

URLParser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(connect(mapStateToProps)(URLParser));
// export default withStyles(useStyles)(connect(mapStateToProps, { setAuth })(Admin));

