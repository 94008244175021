import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import { getAuth } from "../../cache/selectors";
import Menu from '@material-ui/core/Menu';
import firebase from 'firebase';
import Avatar from '@material-ui/core/Avatar';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 20,
        marginLeft: -12
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbarButtons: {
        marginLeft: "auto",
        marginRight: -12
    },
    avatar: {
        margin: 10,
      },
}));

const mapStateToProps = state => {
    const auth = getAuth(state); 
    return { auth };
};

const Navbar = ({ ...props }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleChange(event) {

    }

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={props.handleDrawerToggle}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: props.open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {props.headingText}
                    </Typography>

                    <span className={classes.toolbarButtons}>
                        {props.auth.isSignedIn ? (
                            <div >
                                {/* <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>  */}
                                <Button color="inherit" edge="end" onClick={handleMenu}>
                                    {/* <AccountCircle /> */}
                                    {firebase.auth() && firebase.auth().currentUser && firebase.auth().currentUser.photoURL ? 
                                        <Avatar alt={firebase.auth().currentUser.displayName} src={firebase.auth().currentUser.photoURL} className={classes.avatar} />
                                        : <AccountCircle />
                                    }
                                    {firebase.auth() && firebase.auth().currentUser&&
                                        firebase.auth().currentUser.displayName 
                                    }
                                </Button>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={props.handleLogout}>Logout</MenuItem> 
                                </Menu>
                                 
                            </div>
                        ) : (
                                <Button color="inherit" edge="end" onClick={props.handleLogin}>Login</Button>
                            )
                        }
                    </span>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default connect(mapStateToProps)(Navbar);



{/* <AppBar position="static">
<Toolbar>
    <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="Menu"
    >
        <MenuIcon />
    </IconButton>
    <Typography variant="title" color="inherit">
        My Title
    </Typography>
    <span className={classes.toolbarButtons}>
        <IconButton color="inherit" aria-label="Edit">
            <AccountCircle />
        </IconButton> 
    </span>
</Toolbar>
</AppBar> */}