import React from 'react';
import MaterialTable from 'material-table';
import {
    SaveAlt,
    ChevronRight,
    ChevronLeft,
    Clear,
    ArrowUpward,
    FirstPage,
    LastPage,
    Search,
    ViewColumn,
    FilterList,
    Remove,
} from "@material-ui/icons";

const tableIcons = {
    ResetSearch: Clear,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    Search: Search,
    SortArrow: ArrowUpward,
    ThirdStateCheck: Remove,
    ViewColumn: ViewColumn
};

const options = {
    actionsColumnIndex: -1,
    grouping: true,
    exportButton: true,
    exportAllData: true,
    exportFileName: "28East Data Export",
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    maxBodyHeight: '60vh'
};
const EditableTable = ({...props}) => {
    if(props.title) options.exportFileName = props.title;
    if(props.maxBodyHeight) options.maxBodyHeight = props.maxBodyHeight;
    return (
        <MaterialTable
            icons={tableIcons}
            options={options}
            title={props.title}
            columns={props.columns}
            data={props.data}
            actions={props.actions&& props.actions}
        />
    );
};

export default (EditableTable);
