let isBuffer = require('is-buffer');

module.exports = flatten;
flatten.flatten = flatten;

function flatten (target, opts) {
    opts = opts || {};

    let delimiter = opts.delimiter || '.';
    let maxDepth = opts.maxDepth;
    let output = {};

    function step (object, prev, currentDepth) {
        currentDepth = currentDepth || 1;
        Object.keys(object).forEach(function (key) {
            let value = object[key];
            let isarray = opts.safe && Array.isArray(value);
            let arraytostring = isarray && opts.arraytostring;
            let type = Object.prototype.toString.call(value);
            let isbuffer = isBuffer(value);
            let isobject = (
                type === '[object Object]' ||
                type === '[object Array]'
            );

            let newKey = prev ? prev + delimiter + key : key;

            if (!isarray && !isbuffer && isobject && Object.keys(value).length &&
                (!opts.maxDepth || currentDepth < maxDepth)) {
                return step(value, newKey, currentDepth + 1)
            } else if(arraytostring && !isbuffer && isobject && (!opts.maxDepth || currentDepth < maxDepth)){
                value = JSON.stringify(value)
            }

            output[newKey] = value
        })
    }

    step(target);

    return output
}
