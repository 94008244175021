let UsersController = {};

UsersController.GetUsers = () => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "users/")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

UsersController.AuthorizeUser = (user) => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_BASE_URL + "users/authorize/"+user)
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};
 
UsersController.GetUserToken = (user) => {
    return new Promise((resolve, reject) => {
        console.log(new Date().toString())
        fetch(process.env.REACT_APP_API_BASE_URL + "users/authorize/"+user+"/getUserToken")
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

UsersController.AddUser = (data) => {
    return new Promise((resolve, reject) => { 
        fetch(process.env.REACT_APP_API_BASE_URL + "users/", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

UsersController.UpdateUser = (oldData, newData) => {
    return new Promise((resolve, reject) => { 
        fetch(process.env.REACT_APP_API_BASE_URL + "users/" + oldData.id, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};
 
UsersController.DeleteUser = (id) => {
    return new Promise((resolve, reject) => { 
        fetch(process.env.REACT_APP_API_BASE_URL + "users/" + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(reject)
    })
};

export default UsersController;
