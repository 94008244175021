import React from "react";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { getClients, getProviders, getStatuses, getServices, getAuth } from "../cache/selectors";
import DataController from "../Controllers/DataController";
import ClientsController from "../Controllers/ClientsController";
import Flatten from "../Utils/Flatten";
import ViewTable from "../components/Tables/ViewTable";
import Bar from "../components/Charts/Bar";
import { Refresh, PlayArrow, Pause } from "@material-ui/icons";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  cardContainer: {
    padding: theme.spacing(1),
    textAlign: 'center',
    margin: `${theme.spacing(1)}px auto`,
  },
  card: {
    width: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const mapStateToProps = state => {
  const clients = getClients(state);
  const services = getServices(state);
  const providers = getProviders(state);
  const statuses = getStatuses(state);
  const auth = getAuth(state); 
  return { clients, services, providers, statuses, auth };
};

const CardComponent = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.pos} color="textSecondary">
            {props.title}
          </Typography>
          <Typography variant="h6" component="h1">
            {props.value}
          </Typography>
        </CardContent>
        {
          props.route != '' &&
          <CardActions>
            <Button onClick={() => { props.handleClick(props.route) }} size="small">view</Button>
          </CardActions>
        }
      </Card>
    </div>
  );
}

const DashboardComponents = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs>
          <CardComponent
            title="Total clients"
            route='clients'
            value={props.numClients}
            handleClick={props.handleClick}
          />
        </Grid> <Grid item xs>
          <CardComponent
            title="Total Services"
            route='services'
            value={props.numServices}
            handleClick={props.handleClick}
          />
        </Grid> <Grid item xs>
          <CardComponent
            title="Total Providers"
            route='providers'
            value={props.numProviders}
            handleClick={props.handleClick}
          />
        </Grid> <Grid item xs>
          <CardComponent
            title="Total Statuses"
            route='statuses'
            value={props.numStatuses}
            handleClick={props.handleClick}
          />
        </Grid> 
        {/* <Grid item xs>
          <Bar
             
          />
        </Grid> */}
        {/* <Grid item xs>
          <CardComponent
            title="Today's Requests"
            route=''
            value={props.requestEstimates}
          />
        </Grid> */}
        {/* <CardComponent
          title="Total API Keys"
          route='apimanagement'
          value={props.numAPIKeys}
          handleClick={props.handleClick}
        /> */}
        {/* <Grid item xs={12}>
          <ViewTable
            title="Request origins"
            data={props.requestsData}
            columns={props.requestsColumns}
            maxBodyHeight={'40vh'}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => { props.onRefresh() }
              },
              {
                icon: props.liveViewState.icon,
                tooltip: props.liveViewState.tooltip,
                isFreeAction: true,
                onClick: () => { props.handleLive() }
              }
            ]}
          />
        </Grid> */}
        {!process.env.REACT_APP_EXCLUDE_USAGE_METRICS && 
          <>
            <Grid item xs={6}>
              <ViewTable
                title="Latest Data Updates"
                data={props.providerUpdateData}
                columns={props.providerUpdateColumns}
                maxBodyHeight={'40vh'}
                actions={[
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => { props.onRefresh() }
                  },
                  {
                    icon: props.liveViewState.icon,
                    tooltip: props.liveViewState.tooltip,
                    isFreeAction: true,
                    onClick: () => { props.handleLive() }
                  }
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ViewTable
                title="Client Requests (Today)"
                data={props.clientsUsageData}
                columns={props.clientsUsageColumns}
                maxBodyHeight={'40vh'}
                actions={[
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => { props.onRefresh() }
                  },
                  {
                    icon: props.liveViewState.icon,
                    tooltip: props.liveViewState.tooltip,
                    isFreeAction: true,
                    onClick: () => { props.handleLive() }
                  }
                ]}
              />
            </Grid>
          </>
        }
      </Grid>
    </div>
  );
}

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: 'Dashboard',
      columns: [],
      data: [],
      providerUpdateColumns:[],
      providerUpdateData: [],
      clientsUsageData: [],
      clientsUsageColumns: [],
      liveView: null,
      numClients: props.clients.length,
      numAPIKeys: 0,
      numServices: props.services.length,
      numProviders: props.providers.length,
      numStatuses: props.statuses.length,
      requestEstimates: 0,
      liveViewState: {
        active: false,
        icon: 'play_arrow',
        tooltip: 'Start live view',
      }
    };
    this.onRefresh();
  }

  parseData = (data) => {
    if (data && data.length > 0) {
      this.setState({ data: data });
      let flattened = Flatten(data[0], { safe: true, arraytostring: true });
      let cols = this.addColumns(Object.keys(flattened));
      this.setState({ columns: cols });
    }
  };
  addColumns = (keys) => {
    let columns = [];
    keys.forEach(k => {
      if (k !== "tableData.id")
        columns.push({ title: k, field: k, readonly: true, editable: 'never' });
    });
    return columns
  };

  onRefresh = () => {
   if(!process.env.REACT_APP_EXCLUDE_USAGE_METRICS) { 
    DataController.GetHostRequestByApps().then(results => { 
      this.parseData(results);
    }).catch(err => { console.error(err) })
   }

    DataController.GetProviderLastUpdatedDates().then(results => { 
      // console.log(results)
      if (results && results.length > 0) {
        this.setState({ providerUpdateData: results });
        let flattened = Flatten(results[0], { safe: true, arraytostring: true });
        let cols = this.addColumns(Object.keys(flattened));
        this.setState({ providerUpdateColumns: cols });
      }
    }).catch(err => { console.error(err) }) 

    ClientsController.GetClientsUsage(1440, this.props.auth.token).then(results => { 
      if (results && results.success && results.data.length > 0) {
        let usageResults = results.data.map(usage => {
          usage.api = usage.a
          delete usage.a
          usage.client_name = usage.c
          delete usage.c
          usage.total = usage.t 
          delete usage.t
          return usage
        });
        this.setState({ clientsUsageData: usageResults });
        let flattened = Flatten(usageResults[0], { safe: true, arraytostring: true });
        let cols = this.addColumns(Object.keys(flattened));
        this.setState({ clientsUsageColumns: cols });
      }
    }).catch(err => { console.error(err) })
  }

  handleLive = () => {
    if (!this.state.liveViewState.active) {
      let liveState = this.state.liveViewState
      liveState.active = true;
      liveState.icon = "pause";
      liveState.tooltip = "Pause Live View"
      this.setState({ liveViewState: liveState });
      this.startLive();
    } else {
      let liveState = this.state.liveViewState
      liveState.active = false;
      liveState.icon = "play_arrow";
      liveState.tooltip = "Start Live View (every 30 seconds)"
      this.setState({ liveViewState: liveState });
      this.stopLive();
    }
  }

  startLive = () => {
    let liveView = setInterval(() => {
      this.onRefresh();
    }, 900000);
    this.setState({ liveView: liveView })
  }

  stopLive = () => {
    let liveView = this.state.liveView;
    clearInterval(liveView);
    this.setState({ liveView: null })
  }

  handleaClick = (route) => {
    if (route) {
      this.props.history.push({
        pathname: "/admin/" + route
      });
    }
  }

  componentWillUnmount = () => {
    this.stopLive();
  }

  render() {
    return (
      <DashboardComponents
        numClients={this.state.numClients}
        numAPIKeys={this.state.numAPIKeys}
        numServices={this.state.numServices}
        numProviders={this.state.numProviders}
        numStatuses={this.state.numStatuses}
        requestEstimates={this.state.requestEstimates}
        handleClick={this.handleaClick}
        requestsData={this.state.data}
        requestsColumns={this.state.columns}
        onRefresh={this.onRefresh}
        handleLive={this.handleLive}
        liveViewState={this.state.liveViewState}
        providerUpdateData={this.state.providerUpdateData}
        providerUpdateColumns={this.state.providerUpdateColumns}
        clientsUsageData={this.state.clientsUsageData}
        clientsUsageColumns={this.state.clientsUsageColumns}
      />
    );
  }
}

// Dashboard.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default Dashboard;
export default connect(mapStateToProps)(Dashboard);
