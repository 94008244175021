import React, { Component } from 'react'; 
// import withStyles from "@material-ui/core/styles/withStyles"; 
// import { Doughnut } from 'react-chartjs-2';
 

class BarChart extends Component {
    constructor(props) {
        super(props); 
    } 

    render() { 
        return (
            <div >
                
            </div>
        );
    }
}
 

export default BarChart;
