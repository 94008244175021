import React from "react";
import PropTypes from "prop-types";
import firebase from 'firebase';
import firebaseConfig from '../assets/firebaseConfig.json';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setAuth } from '../cache/actions'
import UsersController from '../Controllers/UsersController'
import { getAuth } from "../cache/selectors";

const mapStateToProps = state => { 
    const auth = getAuth(state);
    return { auth };
};

class Login extends React.Component {
    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccess: () => false
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: !!props.auth
        } 
        if (!firebase.apps.length)
            firebase.initializeApp(firebaseConfig);
    }

    authorizeUser = (user) => {
console.log('auth: ', user)
        Promise.all([
            UsersController.AuthorizeUser(user.email),
            UsersController.GetUserToken(user.email)
        ])
        .then(results => { 
            console.log(results)
            if (results.length >= 2) { 
                let auth = results[0]
                let key = results[1]
                if (auth.length > 0){
                    let authorized = auth[0] 
                    if(key.token) authorized.token = key.token
                    this.setState({ isSignedIn: authorized.view_authorized }); 
                    authorized.isSignedIn = this.state.isSignedIn
                    this.props.setAuth(authorized); 
                } else {
                    this.setState({ isSignedIn: false }); 
                    this.props.setAuth({isSignedIn: this.state.isSignedIn});
                    firebase.auth().signOut(); 
                }
            } else {
                this.setState({ isSignedIn: false }); 
                this.props.setAuth({isSignedIn: this.state.isSignedIn});
                firebase.auth().signOut(); 
            } 
        })
    }

    componentDidMount = () => { 
        firebase.auth().onAuthStateChanged(user => {
            if(!!user){
                this.authorizeUser(user)
            } else{
                this.setState({ isSignedIn: false }); 
                this.props.setAuth({isSignedIn: this.state.isSignedIn});
            } 
        })
    }

    render() {
        const { classes } = this.props;
        return (
            this.props.auth.isSignedIn ? (
                <div>
                    <Redirect from="/" to="/admin/dashboard" />
                </div>
            ) :
                (
                    <StyledFirebaseAuth
                        uiConfig={this.uiConfig}
                        firebaseAuth={firebase.auth()}
                    />
                )
        );
    }
}

// Login.propTypes = {
//     classes: PropTypes.object.isRequired
// };

export default connect(
    mapStateToProps,
    { setAuth }
)(Login);
