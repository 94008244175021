import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import UsersController from "../Controllers/UsersController";
import Flatten from "../Utils/Flatten";
import image from "../assets/img/logo.png";
import { Box, Container, Grid, Paper, Typography, makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getApps, getAuth, getClients } from "../cache/selectors";
import Utils from "../Utils/Utils";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import JsonDisplay from "../components/JsonDisplay/JsonDisplay";
import ControlledOpenSelect from "../components/DropdownSelect/ControlledOpenSelect";
import ClientsController from "../Controllers/ClientsController";
import axios from 'axios'


const mapStateToProps = state => {
    const apps = getApps(state);
    const clients = getClients(state);
    const auth = getAuth(state);
    return { clients, auth , apps};
};

const appStyle = theme => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    frame: {
        padding: '20pt',
        width: '100%'
    },
    srcInput: {
        width: '40%',
        minWidth: '300px'
    },
    srcButton: {
        margin: theme.spacing(1),
    },
    inputContainer: {
        marginBottom: '15pt'
    },
    jsonBlockContainer: {
        textAlign: 'left',
        height: '75vh',
        overflow: 'auto'
    },
});

class FindMyCoverageResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            srcURLInput: '',
            srcURL: '',
            isLoading: false,
            error: false,
            expanded: false,
            response: {},
            selectedAPIKey: null,
            selectedAPIKeyID: null,
            selectedClient: null,
            selectedClientID: null,
            data: [],
            bulkProgress: 0
        };
    }

    componentDidMount() {
        this.initialiseData();
    }

    onRefresh = () => {
        this.initialiseData();
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    initialiseData = () => {
        this.loading(true)
        Promise.all([
            ClientsController.GetAPIKeys()
        ]).then(results => {
            if (results && results.length > 0)
                this.handleResults(results)
        });
    };

    handleResults = results => {
        let data = []

        this.setState({ keyData: results[0] });
        this.setState({ loading: false })
        this.initialiseColumnData();
    }

    initialiseColumnData = () => {
        // let columns = this.state.columns;
        // console.log(this.props.services)
        let clientLookup = []
        let keyLookup = []
        // if(this.props.clients) columns[1].lookup = this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        if (this.props.clients) clientLookup = this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        if (this.state.keyData) {
            // columns[2].lookup = this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description + ' ('+ cur.key + ')'; return acc; }, {});
            keyLookup = this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description + ' (' + cur.key + ')'; return acc; }, {});
        }
        //    this.setState({ columns: columns, clientLookup: clientLookup, availableKeys: keyLookup });
        this.setState({ clientLookup: clientLookup, availableKeys: keyLookup });
        // this.getSubscriptions();
    };


    setClient = clientId => {
        let keys = []
        let clients = this.props.clients.filter(c => c.id === +clientId)
        let client = clients.length > 0 ? clients[0] : null
        if (this.state.keyData && client) {
            let tmp = this.state.keyData.filter(item => { return item.client === client.full_name });
            keys = tmp.reduce((acc, cur, i) => {
                if (cur.description) acc[cur.id] = cur.description + ' (' + cur.key + ')'
                else {
                    let tmp = this.props.apps.filter(item => { return item.id === (+cur.app) });
                    acc[cur.id] = cur.key + ` (${tmp[0].full_name})`;
                }
                return acc;
            }, {});
            this.setState({
                availableKeys: keys,
                selectedClientID: clientId,
                selectedClient: client
            });
        }
    }

    setKey = keyId => {
        if (this.state.keyData && keyId) {
            let keys = this.state.keyData.filter(k => k.id === +keyId)
            let key = keys.length > 0 ? keys[0] : null

            this.setState({
                selectedAPIKey: key,
                selectedAPIKeyID: keyId,
            })
        }
    }

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleChange = (event) => {
        this.setState({ srcURLInput: event.target.value })
    };

    handleToggleExpand = (event) => {
        this.setState({ expanded: !this.state.expanded })
    };

    handleToggleExpand = (event) => {
        this.setState({ expanded: !this.state.expanded })
    };

    loadResults = async () => {
        this.loading(true)

        if(!this.state.selectedAPIKey?.key) {
            this.loading(true)
            this.setState({ response: 'Error: Please select a valid API Key' })
            return
        }

        if(!this.state.srcURLInput) {
            this.loading(true)
            this.setState({ response: 'Error: Please provide a valid Reference' })
            return
        }

        let domain = 'api.coverage.28east.co.za'
        if(process.env.REACT_APP_CONSOLE_ENV.toLowerCase().indexOf('dev') != -1)
            domain = 'api.coverage-dev.28east.co.za'
        else if(process.env.REACT_APP_CONSOLE_ENV.toLowerCase().indexOf('stg') != -1 || process.env.REACT_APP_CONSOLE_ENV.toLowerCase().indexOf('staging') != -1)
            domain = 'staging.api.coverage.28east.co.za'

        const url = `https://${domain}/findmycoverage/retrieve`
        const params = [
            'key='+this.state.selectedAPIKey?.key,
            'ref='+ this.state.srcURLInput
        ]
        // const response = await fetch(url + '?' + params)
        // const results = await response.json()
        try{
            let resp = await axios.get(url + '?' + params.join('&'))

            this.setState({ response: resp.data })
        } catch(e){
            this.setState({ response: e.response?.data || e.message })
        } finally{
            this.loading(false)
        }

    };

    keyPress = (e) => {
        if (e.keyCode == 13) {
            this.loadResults(e)
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root}>
                <Box className={classes.frame}>
                    <Grid container spacing={2} direction='row' justify='space-evenly' alignItems='stretch'>
                        <Grid item xs={6}>
                            <Paper style={classes.paperMap}>
                                <ControlledOpenSelect
                                    label={'Select a Client'}
                                    style={classes.controlledOpenSelect}
                                    onChange={e => {
                                        this.setClient(e);
                                    }}
                                    value={this.state.selectedClient}
                                    lookup={this.state.clientLookup}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper style={classes.paperMap}>
                                <ControlledOpenSelect
                                    label={'Select an API Key'}
                                    style={classes.controlledOpenSelect}
                                    onChange={e => this.setKey(e)}
                                    value={this.state.selectedAPIKey}
                                    lookup={this.state.availableKeys}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.srcInput}
                                id="srcURLInput"
                                label="Client Reference Number"
                                variant="outlined"
                                value={this.state.srcURLInput}
                                onChange={this.handleChange}
                                onKeyDown={this.keyPress}
                                required />
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.srcButton}
                                onClick={this.loadResults}
                            >
                                Get Results
                            </Button>
                        </Grid>
                        <Typography variant="caption">Please enter a valid Reference number to load the results</Typography>
                    </Grid>
                    {/* <Container className={classes.inputContainer}>
                    </Container> */}

                    <Box className={classes.jsonBlockContainer}>
                        <Typography variant="h6">Find My Coverage Results</Typography>
                        <JsonDisplay jsonData={this.state.response} />
                    </Box>
                </Box>
            </Paper>
        );
    }
}

FindMyCoverageResults.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(connect(mapStateToProps)(FindMyCoverageResults));
// export default withStyles(useStyles)(connect(mapStateToProps, { setAuth })(Admin));

