import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import ClientsController from "../Controllers/ClientsController";
import DataController from "../Controllers/DataController";
import Flatten from "../Utils/Flatten";
import Utils from "../Utils/Utils";
import { makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getClients, getAuth } from "../cache/selectors";
import ControlledOpenSelect from "../components/DropdownSelect/ControlledOpenSelect";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
}));

const mapStateToProps = state => {
    const clients = getClients(state);
    const auth = getAuth(state);
    return { clients, auth };
};

class ClientDomains extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Client Domains',
            clients: props.clients,
            api_keys: [],
            availableKeys: [],
            filter: props.location.state ? props.location.state.client : -1,
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                {
                    title: 'Client',
                    field: 'client',
                    required: true,
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => {
                                this.setClient(e);
                                props.onChange(e);
                            }}
                            value={props.value}
                            lookup={this.state.clientLookup}
                        />
                    ),
                    lookup: {}
                },
                {
                    title: 'API Key',
                    field: 'apikey',
                    required: true,
                    editComponent: props => (
                        <ControlledOpenSelect
                            onChange={e => props.onChange(e)}
                            value={props.value}
                            lookup={this.state.availableKeys}
                        />
                    ),
                    lookup: {}
                },
                { title: 'Domains', field: 'domain' },
            ],
            data: [],
        };
    }

    setClient = client => {
        let keys = []
        if(this.state.keyData){
            let tmp = this.state.keyData.filter(item => {return item.client_id === (+client)});
            keys = tmp.reduce((acc, cur, i) => {
                if(cur.description) acc[cur.id] = cur.description + ' ('+ cur.key + ')'
                else {
                    let tmp = this.props.apps.filter(item => {return item.id === (+cur.app)});
                    acc[cur.id] = cur.key + ` (${tmp[0].full_name})`;
                }
                return acc;
            }, {});
            this.setState({availableKeys: keys});
        }
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    componentDidMount() {
        // this.initialiseData();
        // this.initialiseColumnData();
        this.initialiseData();
    }

    onRefresh = () => {
        this.initialiseData();
    }


    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            }

            delete data.key_id
            if (data.apikey && this.state.keyData) {
                data.key_id = data.apikey;
            }
            delete data.status_id
            if (data.status && this.state.keyData) {
                data.status_id = data.status;
            }
            if (data.max_distance) {
                data.max_distance = +data.max_distance;
            } else
                delete data.max_distance
console.log('data', data)
            if (!data.key_id) {
                return reject('Invalid API Key');
            }
            if (!data.domain) {
                return reject('Invalid Domain');
            }

            return resolve(data);
        });
    }

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            ClientsController.AddClientDomain(newData)
                .then(response => {
                    // console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            ClientsController.UpdateClientDomain(oldData, newData)
                .then(response => {
                    // console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            ClientsController.DeleteClientDomain(oldData.id)
                .then(response => {
                    // console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };


    handleError = (error) => {
        this.loading(false)
        alert(error)
    };
    handleRowClick = (client) => {
        console.log(client)
    };

    initialiseColumnData = () => {
        let columns = this.state.columns;
        // console.log(this.props.services)
        let clientLookup = []
        let keyLookup = []
        if(this.props.clients) columns[1].lookup = this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        if(this.props.clients) clientLookup = this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        if(this.state.keyData) {
            columns[2].lookup = this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description + ' ('+ cur.key + ')'; return acc; }, {});
            keyLookup = this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description + ' ('+ cur.key + ')'; return acc; }, {});
        }
       this.setState({ columns: columns, clientLookup: clientLookup, availableKeys: keyLookup });
        // this.getSubscriptions();
    };

    initialiseData = () => {
        this.loading(true)
        Promise.all([
            ClientsController.GetClientsDomains(),
            ClientsController.GetAPIKeys()
        ]).then(results => {
            if (results && results.length > 0)
                this.handleResults(results)
        });
    };

    handleResults = results => {
        let data = []
        if (results[0] && (!results[0].success && !results[0].code) && (!results[1].success && !results[1].code)) {
            let parents = {};
            results[0].forEach(row => {
                let apiDetails = results[1].filter(key => key.id === row.key_id);
                if(apiDetails && apiDetails.length> 0){
                    // row.client = apiDetails[0].client
                    row.apikey = apiDetails[0].id
                    if (this.state.clients && this.state.clients.length > 0) {
                        let x = this.state.clients.filter(e => { return e.full_name === apiDetails[0].client })
                        if (x && x.length > 0) {
                            row.client = x[0].id
                            apiDetails[0].client_id = x[0].id
                        }
                    }
                }
            })
        }
        this.setState({ data: results[0] , keyData: results[1] });
        this.loading(false);
        this.initialiseColumnData();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    validation={this.validation}
                    // handleRowClick={this.handleClientClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    onRefresh={this.onRefresh}
                />
            </div>
        );
    }
}

// Subscriptions.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps)(Subscriptions));
export default connect(mapStateToProps)(ClientDomains);
