import React from 'react';
import MaterialTable from 'material-table';
import Utils from '../../Utils/Utils';
import {
    SaveAlt,
    ChevronRight,
    ChevronLeft,
    AddBox,
    ArrowUpward,
    Check,
    Edit,
    FirstPage,
    LastPage,
    Clear,
    Search,
    ViewColumn,
    DeleteOutline,
    FilterList,
    Remove,
    PinDropTwoTone,
} from "@material-ui/icons";

const tableIcons = {
    Add: AddBox,
    Check: Check,
    Clear: Clear,
    Delete: DeleteOutline,
    DetailPanel: ChevronRight,
    Edit: Edit,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search: Search,
    SortArrow: ArrowUpward,
    ThirdStateCheck: Remove,
    ViewColumn: ViewColumn
};

const options = {
    sorting: true,
    actionsColumnIndex: -1,
    grouping: true,
    exportButton: true,
    exportAllData: true,
    exportFileName: "28East Data Export",
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
    maxBodyHeight: '60vh'
};
let requiredFields = [];

const validateRequiredFields = (data) => {
    let valid = {
        isValid: true,
        fields: []
    };
    if (!Utils.isEmpty(data)) valid.isValid = false;
    if (requiredFields && requiredFields.length > 0) {
        requiredFields.forEach(field => {
            if (!data[field]) {
                valid.isValid = false;
                valid.fields.push(data[field])
            }
        });
    }
    return valid;
};
const initialiseComponents = ({ ...props }) => {
    if (props.columns) {
        let columns = props.columns;
        columns.forEach(column => {
            if (column.required) {
                requiredFields.push(column.field)
            }
            if(column.lookup && typeof column.lookup === 'object' && !Array.isArray(column.lookup)) {
                column.lookup = Utils.sortObjectAlphabetically(column.lookup)
            } else if(column.lookup && typeof column.lookup === 'object' && Array.isArray(column.lookup)) {
                column.lookup = Utils.sortAlphabetically(column.lookup)
            }
        });
    }
};

const EditableTable = ({ ...props }) => {
    if (props.title) options.exportFileName = props.title;
    const mergedOptions = props.options ? Object.assign({}, options, props.options): options
    const actions = props.actions ? props.actions : []
    if (props.onRefresh) actions.push({
        icon: 'refresh',
        tooltip: 'Refresh Data',
        isFreeAction: true,
        onClick: () => { props.onRefresh() }
      })

    initialiseComponents(props);

    return (
        <MaterialTable
            icons={tableIcons}
            options={mergedOptions}
            title={props.title}
            columns={props.columns}
            data={props.data}
            isLoading={props.isLoading}
            actions={actions}
            // parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            editable={{
                isEditable: rowData => props.isEditable === true,
                isDeletable: rowData => props.isDeletable === true,
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        if (Utils.isEmpty(newData)) {
                            props.handleError("Invalid: All fields are empty.");
                            reject("Invalid: All fields are empty.");
                            return
                        }
                        if (props.validation) {
                            props.validation(newData)
                                .then(results => {
                                    props.handleRowAdd(results).then(results => resolve()).catch(reject)
                                })
                                .catch(err => {
                                    props.handleError(err);
                                    reject(err)
                                })
                        } else {
                            props.handleRowAdd(newData).then(results => resolve()).catch(reject)
                        }
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        if (Utils.isEmpty(newData)) {
                            props.handleError("Invalid: All fields are empty.");
                            reject("Invalid: All fields are empty.");
                            return
                        }
                        if (props.validation) {
                            props.validation(newData)
                                .then(results => {
                                    props.handleRowUpdate(oldData, results)
                                    .then(results => resolve())
                                    .catch(err =>{
                                        props.handleError(err);
                                        reject(err)
                                    })
                                })
                                .catch(err => {
                                    props.handleError(err);
                                    reject(err)
                                })
                        } else {
                            props.handleRowUpdate(oldData, newData).then(results => resolve())
                        }
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        props.handleRowRemove(oldData).then(results => resolve())
                            .catch(err => {
                                props.handleError(err);
                                reject(err)
                            });
                    })
            }}
            onRowClick={(event, rowData) => {
                if (props.handleRowClick && !Utils.isEmpty(rowData)) props.handleRowClick(rowData)
            }}
        />
    );
};

export default (EditableTable);
