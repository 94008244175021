import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import DataController from "../Controllers/DataController"; 
import { makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { setApps } from '../cache/actions'
import { getApps, getAuth } from '../cache/selectors'
import Utils from "../Utils/Utils";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
      },
}));
  
const mapStateToProps = state => {
    const Apps = getApps(state);
    const auth = getAuth(state);
    return { Apps, auth };
};

class Apps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Apps',
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Full Name', field: 'full_name' },
                { title: 'Public URL', field: 'public_url' },
                { title: 'Private URL', field: 'private_url' },
                { title: 'Type', field: 'type' },
                { title: 'Description', field: 'description' },
            ],
            data: [],
        }; 
    }
    
    componentDidMount() { 
        this.initialiseData();
    }

    loading = loading => {
        this.setState({isLoading: loading})
    }

    initialiseData = () => {
        this.loading(true)
        DataController.GetApps()
            .then(results => {   
                this.props.setApps(results);
                this.setState({data: results})
                this.loading(false);
            })
            .catch(err => console.error(err));
    };
   
    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            }  

            if (!data.name) {
                return reject('Invalid property: Name');
            }
            if (!data.full_name) {
                return reject('Invalid property: Full Name');
            } 
            if (!data.private_url) {
                return reject('Invalid property: Private Url');
            } 

            return resolve(data);
        });
    }

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate();

            DataController.AddApp(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    self.props.setApps(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            DataController.UpdateApp(oldData, newData)
                .then(response => {
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    self.props.setApps(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            DataController.DeleteApp(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    self.props.setApps(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleRowClick = (row) => {
        console.log(row)
        console.log(this.props) 
    };

    onRefresh = () => { 
        this.initialiseData();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    validation={this.validation}
                    // handleRowClick={this.handleRowClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    onRefresh={this.onRefresh} 
                />
            </div>
        );
    }
}

// Apps.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps, {setApps})(Apps));
export default connect(mapStateToProps, {setApps})(Apps);
