import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import ClientsController from "../Controllers/ClientsController";
import Flatten from "../Utils/Flatten";
import image from "../assets/img/logo.png";
import { makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { setClients } from '../cache/actions'
import { getAuth } from "../cache/selectors";
import Utils from "../Utils/Utils";


const mapStateToProps = state => {
    const auth = getAuth(state);
    return { auth };
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
      },
}));

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Clients',
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Full Name', field: 'full_name' },
                { title: 'Home Page', field: 'homepage' },
                { title: 'Coverage Map URL', field: 'coverage_map_url' },
                { title: 'Token', field: 'token' },
                { title: 'Token Active', field: 'token_active', type: 'boolean' },
                { title: 'Is Reseller', field: 'is_reseller', type: 'boolean' },
                { title: 'active', field: 'active', type: 'boolean' },
            ],
            data: [],
        };
    }

    componentDidMount() {
        this.initialiseData();
    }
    onRefresh = () => {
        this.initialiseData();
    }

    loading = loading => {
        this.setState({isLoading: loading})
    }

    initialiseData = () => {
        this.loading(true)
        ClientsController.GetClients()
            .then(results => {
                this.setState({data: results})
                this.loading(false);
            })
            .catch(err => console.error(err));
    };

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate();

            ClientsController.AddClient(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    if(self.props.setClients) self.props.setClients(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
        // data.push(newData);
        // this.setState({ data: data });
        // this.props.setClients(data);
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            ClientsController.UpdateClient(oldData, newData)
                .then(response => {
                    if(!response.success || !response.code == 200) {
                        throw(response)
                    }
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    this.props.setClients(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
        // data[data.indexOf(oldData)] = newData;
        // this.setState({ data: data });
        // this.props.setClients(data);
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            ClientsController.DeleteClient(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    this.props.setClients(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
        // const data = [...this.state.data];
        // data.splice(data.indexOf(oldData), 1);
        // this.setState({ data: data });
        // this.props.setClients(data);
    };

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleRowClick = (row) => {
        console.log(row)
        console.log(this.props)
        this.props.history.push({
            pathname:"/admin/subscriptions",
            state:{
                client:row.id
             }
           });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    handleRowClick={this.handleRowClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    onRefresh={this.onRefresh}
                />
            </div>
        );
    }
}

// Clients.propTypes = {
//     classes: PropTypes.object.isRequired
// };

export default connect(mapStateToProps, {setClients})(Clients);
