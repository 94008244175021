import {
  SET_APPS,
  SET_CLIENTS,
  SET_SERVICES,
  SET_PROVIDERS,
  SET_STATUSES,
  SET_AUTH
} from './actionTypes'

export const setApps = content => ({
  type: SET_APPS,
  payload: {
    content
  }
})

export const setClients = content => ({
  type: SET_CLIENTS,
  payload: {
    content
  }
})

export const setServices = content => ({
  type: SET_SERVICES,
  payload: {
    content
  }
})

export const setProviders = content => ({
  type: SET_PROVIDERS,
  payload: {
    content
  }
})

export const setStatuses = content => ({
  type: SET_STATUSES,
  payload: {
    content
  }
});

export const setAuth = content => ({
  type: SET_AUTH,
  payload: {
    content
  }
});