import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import DataController from "../Controllers/DataController";
import { makeStyles, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { setStatuses } from '../cache/actions'
import { getStatuses, getAuth } from '../cache/selectors'
import Utils from "../Utils/Utils";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
}));

const mapStateToProps = state => {
    const statuses = getStatuses(state);
    const auth = getAuth(state);
    return { statuses, auth };
};

class Statuses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Statuses',
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                { title: 'Name', field: 'name' },
                { title: 'Full Name', field: 'full_name' },
            ],
            data: [],
        };
    }

    componentDidMount() {
        this.initialiseData();
    }

    onRefresh = () => { 
        this.initialiseData();
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    initialiseData = () => {
        this.loading(true)
        DataController.GetStatuses()
            .then(results => {
                this.props.setStatuses(results);
                this.setState({ data: results })
                this.loading(false);
            })
            .catch(err => console.error(err));
    };
    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            }

            if (!data.name) {
                return reject('Invalid property: Service');
            }
            if (!data.full_name) {
                return reject('Invalid property: Provider');
            }

            return resolve(data);
        });
    }
    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            if (!newData.created_at) newData.created_at = Utils.getCurrentDate();

            DataController.AddStatus(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    self.props.setStatuses(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            if (!newData.key) newData.key = Utils.getUUID();
            DataController.UpdateStatus(oldData, newData)
                .then(response => {
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    self.props.setStatuses(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            DataController.DeleteStatus(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    self.props.setStatuses(data);
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleError = (error) => {
        this.loading(false)
        alert(error)
    };

    handleRowClick = (row) => {
        console.log(row)
        console.log(this.props)
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    validation={this.validation}
                    // handleRowClick={this.handleRowClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    onRefresh={this.onRefresh} 
                />
            </div>
        );
    }
}

// Statuses.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps, {setStatuses})(Statuses));
export default connect(mapStateToProps, { setStatuses })(Statuses);
