// Display only the bulk items in a table from props.data
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import CircularProgress from '@material-ui/core/CircularProgress';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
class BulkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns || [],
      message: null,
      data: props.data || [],
      page: 0,
      rowsPerPage: 100,
      handleColumnsChange: this.handleColumnsChange,
      loading: props.loading || false,
      downloadFile: true,
    };
  }

  componentDidUpdate(nextProps) {
    // console.log('******************************');
    if (this.state.loading !== nextProps.loading)
      this.setState({ loading: nextProps.loading });

    if (this.state.columns !== nextProps.columns)
      this.setState({ columns: nextProps.columns });

    if (this.state.data !== nextProps.data)
      this.setState({ data: nextProps.data });
  }

  componentDidMount() {
    this.reloadList();
  }

  reloadList() {}

  render() {
    const options = {
      responsive: 'scroll',
      rowsPerPage: this.state.rowsPerPage,
      sort: true,
      sortFilterList: false,
      filter: false,
      search: false,
      disableToolbarSelect: true,
      selectableRows: 'none',
      elevation: 0,
      // textLabels: {
      //   body: {
      //     noMatch: 'Not Feasible',
      //   },
      // },
      setRowProps: row => {
        if(row?.some(r => typeof r === 'string' && r.toLowerCase().includes('error'))) {
          return {
            style: {
              background: "#ef535026",
              borderLeft: '10px solid #d50000'
            }
          };
        }
      },
      viewColumns: false,
      downloadOptions: {
        filename: `AvailableCoverage-${this.props.date}.csv`,
        separator: ',',
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        if (this.props.formattedAddress) {
          return `${buildHead(columns)}${buildBody(data)}`;
        } else {
          return `${buildHead(columns)}${buildBody(data)}`;
        }
      },
    };

    return (
      <div style={style.root}>
         {/* <MUIDataTable
              theme={this.getMuiTheme()}
              title='Bulk Feasibility Results'
              data={this.state.data}
              columns={this.state.columns}
              options={options}
            /> */}
        {!this.state.loading ? (
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              theme={this.getMuiTheme()}
              title='Bulk Feasibility Results'
              data={this.state.data}
              columns={this.state.columns}
              options={options}
            />
          </MuiThemeProvider>
        ) : (
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              theme={this.getMuiTheme()}
              title={'Bulk Feasibility Results'}
              data={[]}
              columns={this.state.columns}
              options={options}
            />
            <CircularProgress style={style.spinner} />
          </MuiThemeProvider>
        )}
      </div>
    );
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiPaper: {
          root: {
            padding: '0pt',
            textAlign: 'center',
            height: '80%',
            minHeight: '75vh',
            maxHeight: '82vh',
            display: 'flex',
            flexDirection: 'column'
        }
        },
        MuiToolbar: {
          root: {
            minHeight: '20px !important',
            maxHeight: '64px !important',
            overflow: 'hidden',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            paddingLeft: '0pt !important',
          },
        },
        MuiIconButton: {
          root: {
            padding: '0pt !important',
            marginLeft: '10pt !important',
          },
        },
        MuiTablePagination: {
          root: {
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          },
        },
        MUIDataTableToolbar: {
          left: {
            textAlign: 'left',
          },
        },
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: '100% !important',
          },
        },
        MuiCircularProgress: {
          root: {
            marginTop: '0pt',
            position: 'absolute',
            top: '40vh',
            zIndex: '100',
          },
        },
      },
    });
}

const style = {
  root: {
    height: '95%',
  },
  display: 'flex',
  justifyContent: 'center',
  tableWrapper: {
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  mut: {
    height: '100%',
  },
  primary: {
    color: 'inherit',
  },
  secondary: {
    color: 'inherit',
  },
  spinner: {
    marginTop: '30pt',
  },
};

export default BulkList;
