import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTable from "../components/Tables/EditableTable";
import ClientsController from "../Controllers/ClientsController";
import DataController from "../Controllers/DataController";
import Flatten from "../Utils/Flatten";
import Utils from "../Utils/Utils";
import { makeStyles, TextField, useTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getApps, getClients, getProviders, getServices, getStatuses, getAuth } from "../cache/selectors";
import ControlledOpenSelect from "../components/DropdownSelect/ControlledOpenSelect";
import { ThreeSixty } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
}));

const mapStateToProps = state => {
    const apps = getApps(state);
    const clients = getClients(state);
    const providers = getProviders(state);
    const services = getServices(state);
    const statuses = getStatuses(state);
    const auth = getAuth(state);
    return { apps, clients, providers, services, statuses, auth };
};

class ExternalAPIs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'External APIs',
            filter: props.location.state ? props.location.state.client : -1,
            providers: props.providers,
            services: props.services,
            isLoading: false,
            columns: [
                { title: 'Id', field: 'id', type: 'numeric', readonly: true, export: false, editable: 'never' },
                { title: 'Name', field: 'name', required: true },
                { title: 'Full Name', field: 'full_name', required: true },
                { title: 'Coverage id', field: 'coverage_id', required: true, lookup: {}, editable: 'never' },
                { title: 'Service', field: 'service', required: true, lookup: {} },
                { title: 'Provider', field: 'provider', required: true, lookup: {} },
                { title: 'API URL', field: 'api_url', required: true },
                { title: 'Active', field: 'active', type: 'boolean' },
                {
                    title: 'Request Config',
                    field: 'request_config',
                    cellStyle: {
                        minWidth: '400px'
                    },
                    initialEditValue: '{}',
                    editComponent: props => (
                        <TextField
                            id="request_config"
                            fullWidth
                            multiline
                            rows={10}
                            variant="outlined"
                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                        />
                    )
                }
            ],
            data: [],
            coverageData: []
        };
    }

    loading = loading => {
        this.setState({ isLoading: loading })
    }

    componentDidMount() {
        this.initialiseData();
    }

    onRefresh = () => {
        this.initialiseData();
    }

    validation = (newData) => {
        return new Promise((resolve, reject) => {
            let data = JSON.parse(JSON.stringify(newData));
            if (!data) {
                reject('No data provided');
                return;
            }
            delete data.coverage_id;
            if ((data.service && data.provider) && this.state.coverageData) {
                this.state.coverageData.forEach(row => {
                    if (row.service === data.service && row.provider === data.provider)
                        data.coverage_id = row.id;
                })
            }

            if (!data.name) {
                return reject('Invalid property: Name');
            }
            if (!data.full_name) {
                return reject('Invalid property: Full Name');
            }
            if (!data.api_url) {
                return reject('Invalid property: API URL');
            }
            if (!data.coverage_id) {
                return reject('Invalid Service Provider');
            }

            return resolve(data);
        });
    }

    handleRowAdd = (newData) => {
        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            DataController.AddExternalAPIs(newData)
                .then(response => {
                    console.log(response)
                    data.push(newData);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    };
    handleRowUpdate = (oldData, newData) => {
        if (JSON.stringify(oldData) === JSON.stringify(newData)) return;

        let self = this;
        const data = [...this.state.data];
        return new Promise((resolve, reject) => {
            DataController.UpdateExternalAPIs(oldData, newData)
                .then(response => {
                    console.log(response)
                    data[data.indexOf(oldData)] = newData;
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };
    handleRowRemove = (oldData) => {
        const data = [...this.state.data];
        let self = this;
        return new Promise((resolve, reject) => {
            DataController.DeleteExternalAPIs(oldData.id)
                .then(response => {
                    console.log(response)
                    data.splice(data.indexOf(oldData), 1);
                    self.setState({ data: data });
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        });
    };

    handleError = (error) => {
        this.loading(false)
        alert(error)
    };
    handleRowClick = (client) => {
        console.log(client)
    };

    initialiseColumnData = () => {
        let columns = this.state.columns;
        console.log(this.props.services)

        if (this.state.coverageData) {
            columns[3].lookup = this.state.coverageData.reduce((acc, cur, i) => {
                acc[cur.id] = cur.id;//`Id: ${cur.id}, Service: ${cur.service}, Provider: ${cur.provider}`;
                return acc;
            }, {});
        }

        columns[4].lookup = this.props.services.reduce((acc, cur, i) => { acc[cur.name] = cur.full_name; return acc; }, {});
        columns[5].lookup = this.props.providers.reduce((acc, cur, i) => { acc[cur.name] = cur.full_name; return acc; }, {});


        this.setState({ columns: columns });
        // this.getSubscriptions();
    };

    initialiseData = () => {
        this.loading(true)
        Promise.all([
            DataController.GetExternalAPIs(),
            DataController.GetServiceCoverage()
        ]).then(results => {
            this.handleResults(results)
        });
    };

    handleResults = results => {
        if (!results[0].success && !results[0].code) {
            let requestConfigs = []
            for (let i in results[0]) {
                let config = results[0][i]
                if (config.request_config) config.request_config = JSON.stringify(config.request_config)
                requestConfigs.push(config)
            }

            this.setState({ data: requestConfigs })
        }

        if (!results[1].success && !results[1].code) {
            this.setState({ coverageData: results[1] })
        }

        this.loading(false);
        this.initialiseColumnData();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EditableTable
                    title={this.state.title}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    columns={this.state.columns}
                    handleRowAdd={this.handleRowAdd}
                    handleRowUpdate={this.handleRowUpdate}
                    handleRowRemove={this.handleRowRemove}
                    validation={this.validation}
                    // handleRowClick={this.handleClientClick}
                    handleError={this.handleError}
                    isEditable={this.props.auth.edit_authorized}
                    isDeletable={this.props.auth.edit_authorized}
                    onRefresh={this.onRefresh}
                // options={this.state.options}
                // actions={this.state.actions}
                />
            </div>
        );
    }
}

// Subscriptions.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// export default withStyles(useStyles)(connect(mapStateToProps)(Subscriptions));
export default connect(mapStateToProps)(ExternalAPIs);
