import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppContainer from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './cache/reducers'
// const store = createStore(rootReducer, window.STATE_FROM_SERVER)
const store = createStore(rootReducer)


if (!process.env.REACT_APP_API_BASE_URL) {
    if (process.env.NODE_ENV.toLowerCase().indexOf('prod') > -1)
        process.env.REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PROD;
    else if (process.env.NODE_ENV.toLowerCase().indexOf('staging') > -1 || process.env.NODE_ENV.toLowerCase().indexOf('stg') > -1)
        process.env.REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL_STAGING;
    else if (process.env.NODE_ENV.toLowerCase().indexOf('dev') > -1)
        process.env.REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL_DEV;
    else
        process.env.REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL_LOCAL;
}
ReactDOM.render(
    <Provider store={store}>
        {/* <App /> */}
        <AppContainer />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
