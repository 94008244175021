/* eslint-disable react/prefer-stateless-function */
/* eslint-disable linebreak-style */
// Display only the bulk items in a table from props.data
import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import { CSVLink, CSVDownload } from 'react-csv';

import {
  Hidden,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AssignmentReturned from '@material-ui/icons/AssignmentReturned';
import DeleteIcon from '@material-ui/icons/Delete';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

import LinearProgressWithLabel from '../LinearProgressWithLabel/LinearProgressWithLabel'

import './BulkView.css';
import { red } from '@material-ui/core/colors';

class BulkView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvExportHeaders: [
        { label: 'siteid', key: 'siteid' },
        { label: 'latitude', key: 'latitude' },
        { label: 'longitude', key: 'longitude' },
        { label: 'height', key: 'height' }
      ],
      csvExportData: 'siteid,latitude,longitude,height',
    };
    // console.log("bulkview constructor: props", props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (event) => {
    document.getElementById('hiddenFileInput').click();
  };

  handleDownloadBulkTemplateClick = (event) => {
    document.getElementById('hiddenDownloadBulkTemplate').click();
  };

  render() {
    const {
      handleOnError,
      handleOnFileLoad,
      isDisabled,
      processing,
      errorState,
      canStart,
      uploading,
      key,
      progress
    } = this.props;

    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
      delimitersToGuess: ['\t', '|', ';'],
    };

    let progressBar;
    let buttonText;
    let currentErrorState;

    if (uploading) {
      buttonText = 'Uploading';
    } else if (canStart) {
      buttonText = 'Start Feasibility';
    } else if (processing) {
      // progressBar = <LinearProgress color={'secondary'} />;
      buttonText = 'Processing...';
    } else {
      progressBar = '';
      buttonText = 'Upload File';
    }
    if (errorState) {
      currentErrorState = errorState;
    } else {
      currentErrorState = '';
    }

    return (
      <div style={style.paper}>
        <div style={style.heading}>
          <span>Bulk Feasibility</span>
          <Button
            id="templateButton"
            aria-label="delete"
            // classes={style.downloadIcon}
            color="secondary"
            size="small"
            className={style.templateButton}
            onClick={this.handleDownloadBulkTemplateClick}
            title="Download Bulk Template"
            startIcon={<AssignmentReturned />}
          >
            Download Bulk Template File
            <CSVLink
              id="hiddenDownloadBulkTemplate"
              data={this.state.csvExportData}
              // headers={this.state.csvExportHeaders}
              filename={'bulk-template.csv'}
              className={style.downloadBulkTemplate}
              enclosingCharacter={``}
            ></CSVLink>
          </Button>
        </div>
        <Divider />
        <div style={style.notes}>
          <Box>
            <Typography className={style.title} color="textSecondary" variant="subtitle1" gutterBottom style={{ paddingTop: "8px" }}>
              How to use the bulk feasibility
            </Typography>
            <Divider />
            {/* <Typography color="textSecondary" variant="caption"> */}
            <List dense={true}>
              <ListItem>
                <Typography color="textSecondary" variant="caption">
                  <strong>Follow the below instructions to upload a bulk file for multiple feasibility checks.</strong>
                </Typography>
              </ListItem>
              <ListItem></ListItem>
              <ListItem>
                <Typography color="textSecondary" variant="caption">
                  Step 1:<br></br>Start by downloading the CSV template here and populate the file with a site id, latitude and longitude.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography color="textSecondary" variant="caption">
                  Step 2:<br></br>Add all your sites to the CSV file with a unique site id, latitude and longitude and save it locally to your device.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography color="textSecondary" variant="caption">
                  Step 3:<br></br>Click on the &apos;Upload file&apos; button and select the relevant file.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography color="textSecondary" variant="caption">
                  Step 4:<br></br>Select the &apos;Start Feasibility&apos; button.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography color="textSecondary" variant="caption">
                  Step 5:<br></br><b>Wait for the system to process the file</b> and the results will display accordingly.
                </Typography>
              </ListItem>
              <ListItem></ListItem>
              <ListItem>
                <Typography color="textSecondary" variant="caption">
                  To ensure your file runs successfully take note of the below:
                </Typography>
              </ListItem>
              <ListItem>
                <Typography color='textSecondary' variant='caption'>
                  <ul style={{ paddingLeft: "18px" }}>
                    <li>The file must be uploaded in CSV format.</li>
                    <li>Make sure to use the template format as downloaded, do not change the headings.</li>
                    <li>Ensure you are using decimal degrees geographical coordinates for latitude and longitude, for more information please see <a href="http://wiki.gis.com/wiki/index.php/Decimal_degrees" target='_blank' rel="noreferrer" style={{ color: "rgba(0, 0, 0, 0.54)", fontStyle: "oblique" }}>this</a> article.</li>
                    <li>There is no maximum number of locations, however, <b>you must keep the browser page open until the process has finished</b>.</li>
                    <li>Once the bulk has started, the process can&apos;t be stopped until the results have finished processing. Keep the browser window open until the process has finished.</li>
                    <li>Avoid using comma&apos;s (,) in the &ldquo;siteid&rdquo; field.</li>
                  </ul>
                </Typography>
              </ListItem>
            </List>
            {/* </Typography>  */}
          </Box>
        </div>
        {/* <p className='uploadText'>Select a file to upload</p> */}
        <div style={style.upload}>
          <div style={style.loadingButtonWrapper}>

            {processing && (
              <LinearProgressWithLabel
                color="secondary"
                value={progress}
              />
            )}

            {this.props.canStart || this.props.processing ? (
              <div>
                {!this.props.processing && (
                  <Button
                    type="submit"
                    // fullWidth
                    size="small"
                    variant="contained"
                    style={style.cancelButton}
                    // disabled={processing}
                    onClick={this.props.handleCancel}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  type="submit"
                  // fullWidth
                  size="small"
                  variant="contained"
                  style={
                    processing ? style.processingButton : style.canStartButton
                  }
                  disabled={processing}
                  onClick={this.props.handleStart}
                >
                  {buttonText}
                </Button>
                <br />
                {this.props.fileInfo &&
                  this.props.canStart &&
                  !this.props.processing && (
                    <Typography align="center" variant="caption">
                      Uploaded file: {this.props.fileInfo.name}
                    </Typography>
                  )}
              </div>
            ) : (
              <div>
                {' '}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={processing}
                  onClick={this.handleClick}
                >
                  {buttonText}
                </Button>
                {!this.props.canStart && !this.props.processing && (
                  <Typography align="center" variant="caption">
                    Select a file to upload
                  </Typography>
                )}
              </div>
            )}

          </div>
          <div key={this.props.randomkey || ''}>
            <CSVReader
              cssClass="csv-reader-input"
              label="Select CSV for bulk feasibility test"
              onFileLoaded={handleOnFileLoad}
              onError={handleOnError}
              parserOptions={papaparseOptions}
              inputId="hiddenFileInput"
              inputStyle={{}}
            />
          </div>
          <div style={style.error}>{currentErrorState}</div>
        </div>
      </div>
    );
  }
}



const style = {
  root: {
    flexGrow: 1,
  },
  heading: {
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  paper: {
    padding: '10px',
    textAlign: 'center',
    height: '80vh',
  },
  paperMap: {
    // padding: '20pt',
    textAlign: 'center',
    height: '100%',
  },
  error: {
    marginTop: '5px',
    color: '#EE2624',
  },
  attribution: {
    padding: '10pt',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.5)',
    fontSize: '10pt',
    marginTop: '10px',
  },
  progressbar: {
    marginTop: '5px',
  },
  link: {
    color: 'rgba(0,0,0,0.5)',
  },
  typo: {
    fontSize: '8pt',
  },
  copyright: {
    float: 'left',
  },
  powered: {
    float: 'right',
  },
  button: {
    overflow: 'hidden',
    color: '#fff',
    backgroundColor: '#2196f3',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  notes: {
    maxHeight: '75%',
    overflow: 'auto',
    marginBottom: '10pt',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    right: '12px',
    marginTop: -13,
  },
  loadingButtonWrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  canStartButton: {
    backgroundColor: '#4caf50',
    color: '#fff',
    margin: '5px',
  },
  processing: {},
  cancelButton: {
    margin: '5px',
  },
  processingButton: {
    width: '100%',
  },
};

export default BulkView;
