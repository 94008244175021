import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    jsonBlock: {
        backgroundColor: '#f4f4f4',
        padding: theme.spacing(2),
        fontFamily: 'Courier New, Courier, monospace',
        fontSize: 14,
        lineHeight: 1.4,
        color: '#333',
        cursor: 'pointer',
        // maxHeight: '600px',
        height: '100%',
        overflow: 'auto',
    },
    jsonBlockContainer: {
        textAlign: 'left'
    }
}));

function JsonDisplay({ jsonData }) {
    const classes = useStyles();

    return (
        <Box className={classes.jsonBlockContainer}>
            <pre id="json-container" className={classes.jsonBlock}>
                {JSON.stringify(jsonData || {}, null, 2)}
            </pre>
        </Box>
    );
}

export default JsonDisplay;
