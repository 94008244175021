import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { isObject } from 'lodash'
import BulkView from '../components/Bulk/BulkView'
import BulkList from '../components/Bulk/BulkList/BulkList'
import ClientsController from '../Controllers/ClientsController'
import { connect } from 'react-redux'
import { getClients, getAuth, getApps } from '../cache/selectors'
import ControlledOpenSelect from '../components/DropdownSelect/ControlledOpenSelect'
import { doBulkFeasibilityApiRequests } from '../services/BulkFeasibilityRequests'

const mapStateToProps = state => {
    const apps = getApps(state);
    const clients = getClients(state);
    const auth = getAuth(state);
    return { clients, auth , apps};
};

class Bulk extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            isDisabled: false,
            clients: props.clients,
            api_keys: [],
            availableKeys: [],
            fileData: {},
            feasibilityItems: [],
            headers: [{
                name: 'siteid',
                label: 'SiteID',
                options: {
                    display: true,
                },
            },
            {
                name: 'provider',
                label: 'Provider',
            },
            {
                name: 'type',
                label: 'Type',
            },
            {
                name: 'feasible',
                label: 'Feasibility',
            },
            {
                name: 'status',
                label: 'Status',
            },
            {
                name: 'data',
                label: 'Data',
            }],
            items: [],

            columns: [

            ],
            loading: true,
            formattedAddress: '',
            date: '',
            errorState: '',
            compareObj: null,
            randomKey: 0,
            selectedAPIKey: null,
            selectedAPIKeyID: null,
            selectedClient: null,
            selectedClientID: null,
            data: [],
            bulkProgress: 0
        }
    }

    setClient = clientId => {
        let keys = []
        let clients = this.props.clients.filter(c => c.id === +clientId)
        let client = clients.length > 0 ? clients[0] : null
        if (this.state.keyData && client) {
            let tmp = this.state.keyData.filter(item => { return item.client === client.full_name});
            keys = tmp.reduce((acc, cur, i) => {
                if (cur.description) acc[cur.id] = cur.description + ' (' + cur.key + ')'
                else {
                    let tmp = this.props.apps.filter(item => { return item.id === (+cur.app) });
                    acc[cur.id] = cur.key + ` (${tmp[0].full_name})`;
                }
                return acc;
            }, {});
            this.setState({
                availableKeys: keys,
                selectedClientID: clientId,
                selectedClient: client
            });
        }
    }

    setKey = keyId => {
        if (this.state.keyData && keyId) {
            let keys = this.state.keyData.filter(k => k.id === +keyId)
            let key = keys.length > 0 ? keys[0] : null

            this.setState({
                selectedAPIKey: key,
                selectedAPIKeyID: keyId,
            })
        }
    }

    onRefresh = () => {
        this.initialiseData();
    }

    componentDidMount() {
        this.initialiseData();

        this.prepareColumns()

    }

    componentDidUpdate() {

    }

    initialiseData = () => {
        this.setState({ loading: true })
        Promise.all([
            ClientsController.GetAPIKeys()
        ]).then(results => {
            if (results && results.length > 0)
                this.handleResults(results)
        });
    };

    handleResults = results => {
        let data = []

        this.setState({ keyData: results[0] });
        this.setState({ loading: false })
        this.initialiseColumnData();
    }


    initialiseColumnData = () => {
        // let columns = this.state.columns;
        // console.log(this.props.services)
        let clientLookup = []
        let keyLookup = []
        // if(this.props.clients) columns[1].lookup = this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        if (this.props.clients) clientLookup = this.props.clients.reduce((acc, cur, i) => { acc[cur.id] = cur.full_name; return acc; }, {});
        if (this.state.keyData) {
            // columns[2].lookup = this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description + ' ('+ cur.key + ')'; return acc; }, {});
            keyLookup = this.state.keyData.reduce((acc, cur, i) => { acc[cur.id] = cur.description + ' (' + cur.key + ')'; return acc; }, {});
        }
        //    this.setState({ columns: columns, clientLookup: clientLookup, availableKeys: keyLookup });
        this.setState({ clientLookup: clientLookup, availableKeys: keyLookup });
        // this.getSubscriptions();
    };

    ////////////////
    // File Handling Logic
    ////////////////
    handleOnFileLoad = async (csvdata) => {

        console.log("ITEMS |", this.state.feasibilityItems)

        this.setState({ processing: true })
        this.setState({ loading: true })
        this.setState({ errorState: '' })
        this.setState({ setUploading: true })
        this.setState({bulkProgress: 0})
        // console.log('---------------------------')
        // console.log('handleOnFileLoad', csvdata)
        // console.log('---------------------------')

        let resultData
        // if (data) resultData = await this.getApiResults(data);
        if (
            csvdata[0] &&
            csvdata[0].hasOwnProperty('siteid') &&
            csvdata[0].hasOwnProperty('longitude') &&
            csvdata[0].hasOwnProperty('latitude')
        ) {
            if (this.state.selectedAPIKey?.key) {

                let results = await doBulkFeasibilityApiRequests(csvdata, this.state.selectedAPIKey?.key,
                    (progress) => {
                        // console.log('!!! Progress: ', progress)
                        this.setState({bulkProgress: progress})
                    })

                this.setState({ feasibilityItems: results })
                this.setState({ loading: false })

            } else {
                this.setState({
                    canStart: false,
                    uploading: false,
                    loading: false,
                    errorState: 'Please select an API Key before submitting a bulk request',
                })
            }
        } else if (csvdata[0] && !csvdata[0].hasOwnProperty('siteid')) {
            this.setState({
                uploading: false,
                loading: false,
                errorState: 'File Format Error: siteid column not found in file.',
            })
        } else if (csvdata[0] && !csvdata[0].hasOwnProperty('longitude')) {
            this.setState({
                uploading: false,
                loading: false,
                errorState: 'File Format Error: longitude column not found in file.',
            })
        } else if (csvdata[0] && !csvdata[0].hasOwnProperty('latitude')) {
            this.setState({
                uploading: false,
                loading: false,
                errorState: 'File Format Error: latitude column not found in file.',
            })
        } else if (!csvdata || csvdata.length === 0) {
            this.setState({
                errorState: 'File Format Error: cannot upload an empty file.',
            })
            this.setState({ loading: false })
        }
        this.setState({ processing: false })
        this.setState({ loading: false })
        this.setState({ randomKey: generateKey() });
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.error('Error in CSV Upload:', err)
        this.setState({ errorState: `CSV Upload Error: ', ${err}` })
        this.setState({ processing: false })
        this.setState({ loading: false })
    }

    ////////////////
    // Data Table Handling Logic
    ////////////////

    ////////////////
    // Utility Functions
    ////////////////

    prepareColumns = () => {
        this.setState({ loading: true })
        let columns = []
        let headers = this.state.headers

        headers.forEach((header) => {
            let options

            options = header.options ? { ...header.options } : ''

            columns.push({ label: header.label, name: header.name, options: options })
        })

        this.setState({ columns: columns })
        this.setState({ loading: false })
    }

    render() {
        return (
            <div style={style.root}>
                <Grid container spacing={2} direction='row' justify='space-evenly' alignItems='stretch'>
                    <Grid item xs={6}>
                        <Paper style={style.paperMap}>
                            <ControlledOpenSelect
                                label={'Select a Client'}
                                style={style.controlledOpenSelect}
                                onChange={e => {
                                    this.setClient(e);
                                }}
                                value={this.state.selectedClient}
                                lookup={this.state.clientLookup}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper style={style.paperMap}>
                            <ControlledOpenSelect
                                label={'Select an API Key'}
                                style={style.controlledOpenSelect}
                                onChange={e => this.setKey(e)}
                                value={this.state.selectedAPIKey}
                                lookup={this.state.availableKeys}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction='row' justify='space-evenly' alignItems='stretch'>
                    <Grid item xs={3}>
                        <Paper style={style.paperMap}>
                            <BulkView
                                handleOnError={this.handleOnError}
                                handleOnFileLoad={this.handleOnFileLoad}
                                isDisabled={this.isDisabled}
                                processing={this.state.processing}
                                errorState={this.state.errorState}
                                randomkey={this.state.randomKey}
                                progress={this.state.bulkProgress}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={9}>
                        <Paper style={style.paper}>
                            <BulkList
                                columns={this.state.columns}
                                data={this.state.feasibilityItems}
                                loading={this.state.loading}
                                date={this.state.date}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function generateKey() {
    let key = Math.floor((Math.random() * 100) + 1)
    return (key)
}

const style = {
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: '20pt',
        textAlign: 'center',
        height: '80vh',
    },
    paperMap: {
        // padding: '20pt',
        textAlign: 'left',
        height: '100%',
    },
    controlledOpenSelect: {
        width: '100%'
    },
    attribution: {
        padding: '10pt',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.5)',
        fontSize: '10pt',
        marginTop: '10px',
    },
    link: {
        color: 'rgba(0,0,0,0.5)',
    },
    typo: {
        fontSize: '8pt',
    },
    copyright: {
        float: 'left',
    },
    powered: {
        float: 'right',
    },
}

export default connect(mapStateToProps)(Bulk);
