import { SET_APPS, SET_CLIENTS, SET_SERVICES, SET_PROVIDERS, SET_STATUSES, SET_AUTH } from './actionTypes'

const initialState = {
    apps: [],
    clients: [],
    apikeys: [],
    services: [],
    providers: [],
    statuses: [],
    auth:{isSignedIn: false}
}

export default function (state = initialState, action) {

    switch (action.type) {
        case SET_APPS: {
            const { content } = action.payload; 
            return {
                ...state,
                apps: content
            }
        }
        case SET_CLIENTS: {
            const { content } = action.payload;
            return {
                ...state,
                clients: content
            }
        }
        case SET_PROVIDERS: {
            const { content } = action.payload;
            return {
                ...state,
                providers: content
            }
        }
        case SET_SERVICES: {
            const { content } = action.payload;
            return {
                ...state,
                services: content
            }
        }
        case SET_STATUSES: {
            const { content } = action.payload;
            return {
                ...state,
                statuses: content
            }
        }
        case SET_AUTH: {
            const { content } = action.payload;
            return {
                ...state,
                auth: content
            }
        }
        default:
            return state;
    }
}